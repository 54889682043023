import { useState, useEffect } from "react";

type TBreakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | null;

const useCurrrentBreakPoint = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<TBreakpoint>(null);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      let breakpoint: TBreakpoint = null;

      if (width < 576) {
        breakpoint = "xs";
      } else if (width < 768) {
        breakpoint = "sm";
      } else if (width < 992) {
        breakpoint = "md";
      } else if (width < 1200) {
        breakpoint = "lg";
      } else if (width < 1400) {
        breakpoint = "xl";
      } else {
        breakpoint = "xxl";
      }

      setCurrentBreakpoint(breakpoint);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { currentBreakpoint };
};

export default useCurrrentBreakPoint;
