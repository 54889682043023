import React from "react";
const SingleEvent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={46} fill="none">
    <path
      fill="#3E4957"
      d="M50.001 40.95 44.1 45.17s-1.158-27.413-2.7-40.951c0 0 1.35 0 2.866.007l5.736 36.724Z"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="M1.002 45.02V14.84h42.985L43.94 45.02H1Z"
    />
    <path fill="#16B054" d="M44 4.219.896 4.235.844 15.01H44V4.22Z" />
    <path
      fill="#3E4957"
      d="M9.839 11.204a2.376 2.376 0 0 0 2.378-2.375 2.376 2.376 0 0 0-2.378-2.374A2.376 2.376 0 0 0 7.461 8.83a2.376 2.376 0 0 0 2.378 2.374ZM36.628 11.204a2.376 2.376 0 0 0 2.378-2.375 2.376 2.376 0 0 0-2.378-2.374A2.376 2.376 0 0 0 34.25 8.83a2.376 2.376 0 0 0 2.378 2.374Z"
    />
    <path
      fill="#3E4957"
      d="M9.961.17h-.247c-.589 0-1.066.476-1.066 1.063v6.352c0 .588.477 1.064 1.066 1.064h.247c.589 0 1.066-.476 1.066-1.064V1.233C11.027.646 10.55.17 9.96.17ZM36.743.17h-.248c-.588 0-1.065.476-1.065 1.063v6.352c0 .588.477 1.064 1.065 1.064h.248c.588 0 1.065-.476 1.065-1.064V1.233c0-.587-.477-1.063-1.065-1.063Z"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="M25.555 16.832h4.21c.82 0 1.484.663 1.484 1.48v4.204c0 .818-.664 1.48-1.483 1.48h-4.211c-.82 0-1.483-.662-1.483-1.48v-4.204c0-.817.664-1.48 1.483-1.48Z"
    />
    <path
      fill="#3E4957"
      d="M29.765 24.176h-4.211a1.664 1.664 0 0 1-1.663-1.66V18.31c0-.916.746-1.66 1.663-1.66h4.211c.917 0 1.663.744 1.663 1.66v4.204c0 .916-.746 1.66-1.663 1.66Zm-4.211-7.465c-.884 0-1.603.718-1.603 1.6v4.205c0 .882.719 1.6 1.603 1.6h4.211c.884 0 1.603-.718 1.603-1.6v-4.205c0-.882-.72-1.6-1.603-1.6h-4.211Z"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="M25.555 26.33h4.21c.82 0 1.484.663 1.484 1.48v4.204c0 .818-.664 1.48-1.483 1.48h-4.211c-.82 0-1.483-.662-1.483-1.48V27.81c0-.817.664-1.48 1.483-1.48Z"
    />
    <mask id="a" fill="#fff">
      <path d="M29.765 33.674h-4.211a1.664 1.664 0 0 1-1.663-1.66v-4.205c0-.916.746-1.66 1.663-1.66h4.211c.917 0 1.663.744 1.663 1.66v4.204c0 .916-.746 1.66-1.663 1.66Zm-4.211-7.465c-.884 0-1.603.718-1.603 1.6v4.205c0 .883.719 1.6 1.603 1.6h4.211c.884 0 1.603-.717 1.603-1.6v-4.205c0-.882-.72-1.6-1.603-1.6h-4.211Z" />
    </mask>
    <path
      fill="#fff"
      d="M29.765 33.674h-4.211a1.664 1.664 0 0 1-1.663-1.66v-4.205c0-.916.746-1.66 1.663-1.66h4.211c.917 0 1.663.744 1.663 1.66v4.204c0 .916-.746 1.66-1.663 1.66Zm-4.211-7.465c-.884 0-1.603.718-1.603 1.6v4.205c0 .883.719 1.6 1.603 1.6h4.211c.884 0 1.603-.717 1.603-1.6v-4.205c0-.882-.72-1.6-1.603-1.6h-4.211Z"
    />
    <path
      fill="#3E4957"
      d="M29.765 33.374h-4.211v.6h4.211v-.6Zm-4.211 0a1.364 1.364 0 0 1-1.363-1.36h-.6c0 1.08.88 1.96 1.963 1.96v-.6Zm-1.363-1.36v-4.205h-.6v4.204h.6Zm0-4.205c0-.75.611-1.36 1.363-1.36v-.6a1.964 1.964 0 0 0-1.963 1.96h.6Zm1.363-1.36h4.211v-.6h-4.211v.6Zm4.211 0c.752 0 1.363.61 1.363 1.36h.6c0-1.082-.881-1.96-1.963-1.96v.6Zm1.363 1.36v4.204h.6V27.81h-.6Zm0 4.204c0 .75-.611 1.36-1.363 1.36v.6a1.964 1.964 0 0 0 1.963-1.96h-.6Zm-5.574-6.104c-1.05 0-1.903.852-1.903 1.9h.6c0-.716.584-1.3 1.303-1.3v-.6Zm-1.903 1.9v4.205h.6v-4.205h-.6Zm0 4.205c0 1.049.854 1.9 1.903 1.9v-.6a1.304 1.304 0 0 1-1.303-1.3h-.6Zm1.903 1.9h4.211v-.6h-4.211v.6Zm4.211 0a1.904 1.904 0 0 0 1.903-1.9h-.6c0 .716-.584 1.3-1.303 1.3v.6Zm1.903-1.9v-4.205h-.6v4.205h.6Zm0-4.205c0-1.048-.854-1.9-1.903-1.9v.6c.719 0 1.303.584 1.303 1.3h.6Zm-1.903-1.9h-4.211v.6h4.211v-.6Z"
      mask="url(#a)"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="M25.555 35.822h4.21c.82 0 1.484.663 1.484 1.48v4.204c0 .818-.664 1.48-1.483 1.48h-4.211c-.82 0-1.483-.662-1.483-1.48v-4.204c0-.817.664-1.48 1.483-1.48Z"
    />
    <mask id="b" fill="#fff">
      <path d="M29.765 43.166h-4.211a1.664 1.664 0 0 1-1.663-1.66V37.3c0-.915.746-1.66 1.663-1.66h4.211c.917 0 1.663.745 1.663 1.66v4.205c0 .915-.746 1.66-1.663 1.66ZM25.554 35.7c-.884 0-1.603.718-1.603 1.6v4.205c0 .883.719 1.6 1.603 1.6h4.211c.884 0 1.603-.717 1.603-1.6v-4.205c0-.882-.72-1.6-1.603-1.6h-4.211Z" />
    </mask>
    <path
      fill="#fff"
      d="M29.765 43.166h-4.211a1.664 1.664 0 0 1-1.663-1.66V37.3c0-.915.746-1.66 1.663-1.66h4.211c.917 0 1.663.745 1.663 1.66v4.205c0 .915-.746 1.66-1.663 1.66ZM25.554 35.7c-.884 0-1.603.718-1.603 1.6v4.205c0 .883.719 1.6 1.603 1.6h4.211c.884 0 1.603-.717 1.603-1.6v-4.205c0-.882-.72-1.6-1.603-1.6h-4.211Z"
    />
    <path
      fill="#3E4957"
      d="M29.765 42.866h-4.211v.6h4.211v-.6Zm-4.211 0a1.364 1.364 0 0 1-1.363-1.36h-.6c0 1.081.88 1.96 1.963 1.96v-.6Zm-1.363-1.36V37.3h-.6v4.205h.6Zm0-4.205c0-.75.611-1.36 1.363-1.36v-.6a1.964 1.964 0 0 0-1.963 1.96h.6Zm1.363-1.36h4.211v-.6h-4.211v.6Zm4.211 0c.752 0 1.363.61 1.363 1.36h.6c0-1.082-.881-1.96-1.963-1.96v.6Zm1.363 1.36v4.205h.6V37.3h-.6Zm0 4.205c0 .749-.611 1.36-1.363 1.36v.6a1.964 1.964 0 0 0 1.963-1.96h-.6ZM25.554 35.4c-1.05 0-1.903.852-1.903 1.9h.6c0-.716.584-1.3 1.303-1.3v-.6Zm-1.903 1.9v4.205h.6v-4.205h-.6Zm0 4.205c0 1.049.854 1.9 1.903 1.9v-.6a1.304 1.304 0 0 1-1.303-1.3h-.6Zm1.903 1.9h4.211v-.6h-4.211v.6Zm4.211 0a1.904 1.904 0 0 0 1.903-1.9h-.6c0 .716-.584 1.3-1.303 1.3v.6Zm1.903-1.9v-4.205h-.6v4.205h.6Zm0-4.205c0-1.048-.854-1.9-1.903-1.9v.6c.719 0 1.303.584 1.303 1.3h.6Zm-1.903-1.9h-4.211v.6h4.211v-.6Z"
      mask="url(#b)"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="M5.313 16.832h4.21c.82 0 1.484.663 1.484 1.48v4.204c0 .818-.664 1.48-1.483 1.48H5.313c-.82 0-1.483-.662-1.483-1.48v-4.204c0-.817.663-1.48 1.483-1.48Z"
    />
    <path
      fill="#3E4957"
      d="M9.523 24.176H5.31a1.664 1.664 0 0 1-1.663-1.66V18.31c0-.916.747-1.66 1.663-1.66h4.212c.917 0 1.663.744 1.663 1.66v4.204c0 .916-.746 1.66-1.663 1.66ZM5.31 16.71c-.883 0-1.603.718-1.603 1.6v4.205c0 .882.72 1.6 1.603 1.6h4.212c.884 0 1.603-.718 1.603-1.6v-4.205c0-.882-.72-1.6-1.603-1.6H5.31Z"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="M5.313 26.33h4.21c.82 0 1.484.663 1.484 1.48v4.204c0 .818-.664 1.48-1.483 1.48H5.313c-.82 0-1.483-.662-1.483-1.48V27.81c0-.817.663-1.48 1.483-1.48Z"
    />
    <mask id="c" fill="#fff">
      <path d="M9.523 33.674H5.31a1.664 1.664 0 0 1-1.663-1.66v-4.205c0-.916.747-1.66 1.663-1.66h4.212c.917 0 1.663.744 1.663 1.66v4.204c0 .916-.746 1.66-1.663 1.66ZM5.31 26.209c-.883 0-1.603.718-1.603 1.6v4.205c0 .883.72 1.6 1.603 1.6h4.212c.884 0 1.603-.717 1.603-1.6v-4.205c0-.882-.72-1.6-1.603-1.6H5.31Z" />
    </mask>
    <path
      fill="#fff"
      d="M9.523 33.674H5.31a1.664 1.664 0 0 1-1.663-1.66v-4.205c0-.916.747-1.66 1.663-1.66h4.212c.917 0 1.663.744 1.663 1.66v4.204c0 .916-.746 1.66-1.663 1.66ZM5.31 26.209c-.883 0-1.603.718-1.603 1.6v4.205c0 .883.72 1.6 1.603 1.6h4.212c.884 0 1.603-.717 1.603-1.6v-4.205c0-.882-.72-1.6-1.603-1.6H5.31Z"
    />
    <path
      fill="#3E4957"
      d="M9.523 33.374H5.31v.6h4.212v-.6Zm-4.212 0a1.364 1.364 0 0 1-1.363-1.36h-.6c0 1.08.881 1.96 1.963 1.96v-.6Zm-1.363-1.36v-4.205h-.6v4.204h.6Zm0-4.205c0-.75.612-1.36 1.363-1.36v-.6a1.964 1.964 0 0 0-1.963 1.96h.6Zm1.363-1.36h4.212v-.6H5.31v.6Zm4.212 0c.751 0 1.363.61 1.363 1.36h.6c0-1.082-.881-1.96-1.963-1.96v.6Zm1.363 1.36v4.204h.6V27.81h-.6Zm0 4.204c0 .75-.612 1.36-1.363 1.36v.6a1.964 1.964 0 0 0 1.963-1.96h-.6ZM5.31 25.91c-1.049 0-1.903.852-1.903 1.9h.6c0-.716.585-1.3 1.303-1.3v-.6Zm-1.903 1.9v4.205h.6v-4.205h-.6Zm0 4.205c0 1.049.854 1.9 1.903 1.9v-.6a1.304 1.304 0 0 1-1.303-1.3h-.6Zm1.903 1.9h4.212v-.6H5.31v.6Zm4.212 0a1.904 1.904 0 0 0 1.903-1.9h-.6c0 .716-.585 1.3-1.303 1.3v.6Zm1.903-1.9v-4.205h-.6v4.205h.6Zm0-4.205c0-1.048-.854-1.9-1.903-1.9v.6c.719 0 1.303.584 1.303 1.3h.6Zm-1.903-1.9H5.31v.6h4.212v-.6Z"
      mask="url(#c)"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="M5.313 35.822h4.21c.82 0 1.484.663 1.484 1.48v4.204c0 .818-.664 1.48-1.483 1.48H5.313c-.82 0-1.483-.662-1.483-1.48v-4.204c0-.817.663-1.48 1.483-1.48Z"
    />
    <mask id="d" fill="#fff">
      <path d="M9.523 43.166H5.31a1.664 1.664 0 0 1-1.663-1.66V37.3c0-.915.747-1.66 1.663-1.66h4.212c.917 0 1.663.745 1.663 1.66v4.205c0 .915-.746 1.66-1.663 1.66ZM5.31 35.7c-.883 0-1.603.718-1.603 1.6v4.205c0 .883.72 1.6 1.603 1.6h4.212c.884 0 1.603-.717 1.603-1.6v-4.205c0-.882-.72-1.6-1.603-1.6H5.31Z" />
    </mask>
    <path
      fill="#fff"
      d="M9.523 43.166H5.31a1.664 1.664 0 0 1-1.663-1.66V37.3c0-.915.747-1.66 1.663-1.66h4.212c.917 0 1.663.745 1.663 1.66v4.205c0 .915-.746 1.66-1.663 1.66ZM5.31 35.7c-.883 0-1.603.718-1.603 1.6v4.205c0 .883.72 1.6 1.603 1.6h4.212c.884 0 1.603-.717 1.603-1.6v-4.205c0-.882-.72-1.6-1.603-1.6H5.31Z"
    />
    <path
      fill="#3E4957"
      d="M9.523 42.866H5.31v.6h4.212v-.6Zm-4.212 0a1.364 1.364 0 0 1-1.363-1.36h-.6c0 1.081.881 1.96 1.963 1.96v-.6Zm-1.363-1.36V37.3h-.6v4.205h.6Zm0-4.205c0-.75.612-1.36 1.363-1.36v-.6a1.964 1.964 0 0 0-1.963 1.96h.6Zm1.363-1.36h4.212v-.6H5.31v.6Zm4.212 0c.751 0 1.363.61 1.363 1.36h.6c0-1.082-.881-1.96-1.963-1.96v.6Zm1.363 1.36v4.205h.6V37.3h-.6Zm0 4.205c0 .749-.612 1.36-1.363 1.36v.6a1.964 1.964 0 0 0 1.963-1.96h-.6ZM5.31 35.4c-1.049 0-1.903.852-1.903 1.9h.6c0-.716.585-1.3 1.303-1.3v-.6Zm-1.903 1.9v4.205h.6v-4.205h-.6Zm0 4.205c0 1.049.854 1.9 1.903 1.9v-.6a1.304 1.304 0 0 1-1.303-1.3h-.6Zm1.903 1.9h4.212v-.6H5.31v.6Zm4.212 0a1.904 1.904 0 0 0 1.903-1.9h-.6c0 .716-.585 1.3-1.303 1.3v.6Zm1.903-1.9v-4.205h-.6v4.205h.6Zm0-4.205c0-1.048-.854-1.9-1.903-1.9v.6c.719 0 1.303.584 1.303 1.3h.6Zm-1.903-1.9H5.31v.6h4.212v-.6Z"
      mask="url(#d)"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="M35.453 16.832h4.211c.82 0 1.483.663 1.483 1.48v4.204c0 .818-.663 1.48-1.483 1.48h-4.21c-.82 0-1.484-.662-1.484-1.48v-4.204c0-.817.664-1.48 1.483-1.48Z"
    />
    <path
      fill="#3E4957"
      d="M39.663 24.176h-4.211a1.664 1.664 0 0 1-1.663-1.66V18.31c0-.916.746-1.66 1.663-1.66h4.212c.916 0 1.663.744 1.663 1.66v4.204c0 .916-.747 1.66-1.663 1.66Zm-4.211-7.465c-.884 0-1.603.718-1.603 1.6v4.205c0 .882.72 1.6 1.603 1.6h4.212c.883 0 1.603-.718 1.603-1.6v-4.205c0-.882-.72-1.6-1.604-1.6h-4.211Z"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="M35.453 26.328h4.211c.82 0 1.483.663 1.483 1.48v4.204c0 .818-.663 1.48-1.483 1.48h-4.21c-.82 0-1.484-.662-1.484-1.48v-4.204c0-.817.664-1.48 1.483-1.48Z"
    />
    <mask id="e" fill="#fff">
      <path d="M39.663 33.672h-4.211a1.664 1.664 0 0 1-1.663-1.66v-4.205c0-.915.746-1.66 1.663-1.66h4.212c.916 0 1.663.745 1.663 1.66v4.204c0 .916-.747 1.66-1.663 1.66Zm-4.211-7.465c-.884 0-1.603.718-1.603 1.6v4.205c0 .883.72 1.6 1.603 1.6h4.212c.883 0 1.603-.718 1.603-1.6v-4.205c0-.882-.72-1.6-1.604-1.6h-4.211Z" />
    </mask>
    <path
      fill="#fff"
      d="M39.663 33.672h-4.211a1.664 1.664 0 0 1-1.663-1.66v-4.205c0-.915.746-1.66 1.663-1.66h4.212c.916 0 1.663.745 1.663 1.66v4.204c0 .916-.747 1.66-1.663 1.66Zm-4.211-7.465c-.884 0-1.603.718-1.603 1.6v4.205c0 .883.72 1.6 1.603 1.6h4.212c.883 0 1.603-.718 1.603-1.6v-4.205c0-.882-.72-1.6-1.604-1.6h-4.211Z"
    />
    <path
      fill="#3E4957"
      d="M39.663 33.372h-4.211v.6h4.212v-.6Zm-4.211 0a1.364 1.364 0 0 1-1.363-1.36h-.6c0 1.08.881 1.96 1.963 1.96v-.6Zm-1.363-1.36v-4.205h-.6v4.204h.6Zm0-4.205c0-.75.611-1.36 1.363-1.36v-.6a1.964 1.964 0 0 0-1.963 1.96h.6Zm1.363-1.36h4.212v-.6h-4.212v.6Zm4.212 0c.751 0 1.362.61 1.362 1.36h.6c0-1.082-.88-1.96-1.962-1.96v.6Zm1.362 1.36v4.204h.6v-4.204h-.6Zm0 4.204c0 .75-.611 1.36-1.363 1.36v.6a1.964 1.964 0 0 0 1.964-1.96h-.6Zm-5.574-6.104c-1.05 0-1.903.852-1.903 1.9h.6c0-.716.584-1.3 1.303-1.3v-.6Zm-1.903 1.9v4.205h.6v-4.205h-.6Zm0 4.205c0 1.049.854 1.9 1.903 1.9v-.6a1.304 1.304 0 0 1-1.303-1.3h-.6Zm1.903 1.9h4.212v-.6h-4.212v.6Zm4.212 0c1.049 0 1.903-.851 1.903-1.9h-.6c0 .716-.585 1.3-1.303 1.3v.6Zm1.903-1.9v-4.205h-.6v4.205h.6Zm0-4.205c0-1.048-.854-1.9-1.904-1.9v.6c.72 0 1.304.584 1.304 1.3h.6Zm-1.904-1.9h-4.211v.6h4.212v-.6Z"
      mask="url(#e)"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="M35.453 35.82h4.211c.82 0 1.483.663 1.483 1.48v4.205c0 .817-.663 1.48-1.483 1.48h-4.21c-.82 0-1.484-.663-1.484-1.48V37.3c0-.817.664-1.48 1.483-1.48Z"
    />
    <mask id="f" fill="#fff">
      <path d="M39.663 43.164h-4.211a1.664 1.664 0 0 1-1.663-1.66v-4.205c0-.915.746-1.66 1.663-1.66h4.212c.916 0 1.663.745 1.663 1.66v4.205c0 .915-.747 1.66-1.663 1.66Zm-4.211-7.465c-.884 0-1.603.718-1.603 1.6v4.205c0 .883.72 1.6 1.603 1.6h4.212c.883 0 1.603-.717 1.603-1.6V37.3c0-.883-.72-1.601-1.604-1.601h-4.211Z" />
    </mask>
    <path
      fill="#fff"
      d="M39.663 43.164h-4.211a1.664 1.664 0 0 1-1.663-1.66v-4.205c0-.915.746-1.66 1.663-1.66h4.212c.916 0 1.663.745 1.663 1.66v4.205c0 .915-.747 1.66-1.663 1.66Zm-4.211-7.465c-.884 0-1.603.718-1.603 1.6v4.205c0 .883.72 1.6 1.603 1.6h4.212c.883 0 1.603-.717 1.603-1.6V37.3c0-.883-.72-1.601-1.604-1.601h-4.211Z"
    />
    <path
      fill="#3E4957"
      d="M39.663 42.864h-4.211v.6h4.212v-.6Zm-4.211 0a1.364 1.364 0 0 1-1.363-1.36h-.6c0 1.081.881 1.96 1.963 1.96v-.6Zm-1.363-1.36v-4.205h-.6v4.205h.6Zm0-4.205c0-.75.611-1.36 1.363-1.36v-.6a1.964 1.964 0 0 0-1.963 1.96h.6Zm1.363-1.36h4.212v-.6h-4.212v.6Zm4.212 0c.751 0 1.362.61 1.362 1.36h.6c0-1.081-.88-1.96-1.962-1.96v.6Zm1.362 1.36v4.205h.6v-4.205h-.6Zm0 4.205c0 .749-.611 1.36-1.363 1.36v.6a1.964 1.964 0 0 0 1.964-1.96h-.6Zm-5.574-6.105c-1.05 0-1.903.852-1.903 1.9h.6c0-.716.584-1.3 1.303-1.3v-.6Zm-1.903 1.9v4.205h.6V37.3h-.6Zm0 4.205c0 1.049.854 1.9 1.903 1.9v-.6a1.304 1.304 0 0 1-1.303-1.3h-.6Zm1.903 1.9h4.212v-.6h-4.212v.6Zm4.212 0c1.049 0 1.903-.851 1.903-1.9h-.6c0 .717-.585 1.3-1.303 1.3v.6Zm1.903-1.9V37.3h-.6v4.204h.6Zm0-4.204c0-1.049-.854-1.901-1.904-1.901v.6c.72 0 1.304.584 1.304 1.3h.6Zm-1.904-1.901h-4.211v.6h4.212v-.6Z"
      mask="url(#f)"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="M15.656 16.832h4.211c.82 0 1.483.663 1.483 1.48v4.204c0 .818-.663 1.48-1.483 1.48h-4.21c-.82 0-1.484-.662-1.484-1.48v-4.204c0-.817.664-1.48 1.483-1.48Z"
    />
    <path
      fill="#3E4957"
      d="M19.867 24.176h-4.212a1.664 1.664 0 0 1-1.663-1.66V18.31c0-.916.746-1.66 1.663-1.66h4.212c.916 0 1.663.744 1.663 1.66v4.204c0 .916-.747 1.66-1.663 1.66Zm-4.212-7.465c-.884 0-1.603.718-1.603 1.6v4.205c0 .882.72 1.6 1.603 1.6h4.212c.883 0 1.603-.718 1.603-1.6v-4.205c0-.882-.72-1.6-1.603-1.6h-4.212Z"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="M15.656 26.328h4.211c.82 0 1.483.663 1.483 1.48v4.204c0 .818-.663 1.48-1.483 1.48h-4.21c-.82 0-1.484-.662-1.484-1.48v-4.204c0-.817.664-1.48 1.483-1.48Z"
    />
    <mask id="g" fill="#fff">
      <path d="M19.867 33.672h-4.212a1.664 1.664 0 0 1-1.663-1.66v-4.205c0-.915.746-1.66 1.663-1.66h4.212c.916 0 1.663.745 1.663 1.66v4.204c0 .916-.747 1.66-1.663 1.66Zm-4.212-7.465c-.884 0-1.603.718-1.603 1.6v4.205c0 .883.72 1.6 1.603 1.6h4.212c.883 0 1.603-.718 1.603-1.6v-4.205c0-.882-.72-1.6-1.603-1.6h-4.212Z" />
    </mask>
    <path
      fill="#fff"
      d="M19.867 33.672h-4.212a1.664 1.664 0 0 1-1.663-1.66v-4.205c0-.915.746-1.66 1.663-1.66h4.212c.916 0 1.663.745 1.663 1.66v4.204c0 .916-.747 1.66-1.663 1.66Zm-4.212-7.465c-.884 0-1.603.718-1.603 1.6v4.205c0 .883.72 1.6 1.603 1.6h4.212c.883 0 1.603-.718 1.603-1.6v-4.205c0-.882-.72-1.6-1.603-1.6h-4.212Z"
    />
    <path
      fill="#3E4957"
      d="M19.867 33.372h-4.212v.6h4.212v-.6Zm-4.212 0a1.364 1.364 0 0 1-1.363-1.36h-.6c0 1.08.881 1.96 1.963 1.96v-.6Zm-1.363-1.36v-4.205h-.6v4.204h.6Zm0-4.205c0-.75.612-1.36 1.363-1.36v-.6a1.964 1.964 0 0 0-1.963 1.96h.6Zm1.363-1.36h4.212v-.6h-4.212v.6Zm4.212 0c.751 0 1.363.61 1.363 1.36h.6c0-1.082-.881-1.96-1.963-1.96v.6Zm1.363 1.36v4.204h.6v-4.204h-.6Zm0 4.204c0 .75-.612 1.36-1.363 1.36v.6a1.964 1.964 0 0 0 1.963-1.96h-.6Zm-5.575-6.104a1.904 1.904 0 0 0-1.903 1.9h.6c0-.716.585-1.3 1.303-1.3v-.6Zm-1.903 1.9v4.205h.6v-4.205h-.6Zm0 4.205c0 1.049.854 1.9 1.903 1.9v-.6a1.304 1.304 0 0 1-1.303-1.3h-.6Zm1.903 1.9h4.212v-.6h-4.212v.6Zm4.212 0c1.049 0 1.903-.851 1.903-1.9h-.6c0 .716-.585 1.3-1.303 1.3v.6Zm1.903-1.9v-4.205h-.6v4.205h.6Zm0-4.205c0-1.048-.854-1.9-1.903-1.9v.6c.718 0 1.303.584 1.303 1.3h.6Zm-1.903-1.9h-4.212v.6h4.212v-.6Z"
      mask="url(#g)"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="M15.656 35.82h4.211c.82 0 1.483.663 1.483 1.48v4.205c0 .817-.663 1.48-1.483 1.48h-4.21c-.82 0-1.484-.663-1.484-1.48V37.3c0-.817.664-1.48 1.483-1.48Z"
    />
    <mask id="h" fill="#fff">
      <path d="M19.867 43.164h-4.212a1.664 1.664 0 0 1-1.663-1.66v-4.205c0-.915.746-1.66 1.663-1.66h4.212c.916 0 1.663.745 1.663 1.66v4.205c0 .915-.747 1.66-1.663 1.66Zm-4.212-7.465c-.884 0-1.603.718-1.603 1.6v4.205c0 .883.72 1.6 1.603 1.6h4.212c.883 0 1.603-.717 1.603-1.6V37.3c0-.883-.72-1.601-1.603-1.601h-4.212Z" />
    </mask>
    <path
      fill="#fff"
      d="M19.867 43.164h-4.212a1.664 1.664 0 0 1-1.663-1.66v-4.205c0-.915.746-1.66 1.663-1.66h4.212c.916 0 1.663.745 1.663 1.66v4.205c0 .915-.747 1.66-1.663 1.66Zm-4.212-7.465c-.884 0-1.603.718-1.603 1.6v4.205c0 .883.72 1.6 1.603 1.6h4.212c.883 0 1.603-.717 1.603-1.6V37.3c0-.883-.72-1.601-1.603-1.601h-4.212Z"
    />
    <path
      fill="#3E4957"
      d="M19.867 42.864h-4.212v.6h4.212v-.6Zm-4.212 0a1.364 1.364 0 0 1-1.363-1.36h-.6c0 1.081.881 1.96 1.963 1.96v-.6Zm-1.363-1.36v-4.205h-.6v4.205h.6Zm0-4.205c0-.75.612-1.36 1.363-1.36v-.6a1.964 1.964 0 0 0-1.963 1.96h.6Zm1.363-1.36h4.212v-.6h-4.212v.6Zm4.212 0c.751 0 1.363.61 1.363 1.36h.6c0-1.081-.881-1.96-1.963-1.96v.6Zm1.363 1.36v4.205h.6v-4.205h-.6Zm0 4.205c0 .749-.612 1.36-1.363 1.36v.6a1.964 1.964 0 0 0 1.963-1.96h-.6Zm-5.575-6.105a1.904 1.904 0 0 0-1.903 1.9h.6c0-.716.585-1.3 1.303-1.3v-.6Zm-1.903 1.9v4.205h.6V37.3h-.6Zm0 4.205c0 1.049.854 1.9 1.903 1.9v-.6a1.304 1.304 0 0 1-1.303-1.3h-.6Zm1.903 1.9h4.212v-.6h-4.212v.6Zm4.212 0c1.049 0 1.903-.851 1.903-1.9h-.6c0 .717-.585 1.3-1.303 1.3v.6Zm1.903-1.9V37.3h-.6v4.204h.6Zm0-4.204c0-1.049-.854-1.901-1.903-1.901v.6c.718 0 1.303.584 1.303 1.3h.6Zm-1.903-1.901h-4.212v.6h4.212v-.6Z"
      mask="url(#h)"
    />
    <path
      fill="#16B054"
      d="m17.749 25.459 1.528 2.257 2.736.681-1.791 2.076.162 2.678-2.635-.974-2.635.974.162-2.678-1.79-2.076 2.735-.681 1.528-2.257Z"
    />
  </svg>
);
export default SingleEvent;
