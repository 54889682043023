import { ApolloClient, InMemoryCache } from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { onError } from "@apollo/client/link/error";

const errorLink = onError(({ networkError }) => {
  if (
    networkError &&
    "statusCode" in networkError &&
    (networkError.statusCode === 401 || networkError.statusCode === 403)
  ) {
    const urlObj = new URL(window.location.href);
    const pathSegments = urlObj.pathname.split("/").filter(Boolean);
    const currentLang =
      pathSegments.length > 0 && pathSegments[0].length === 2
        ? "/" + pathSegments[0]
        : "";
    window.location.href = `${process.env.REACT_APP_TICKET_URL}${currentLang}/for-organizers/login`;
  }
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_TICKET_URL + "/for-organizers/graphql",
  credentials: "include",
});

export const apolloClient = new ApolloClient({
  link: errorLink.concat(uploadLink),
  cache: new InMemoryCache(),
});
