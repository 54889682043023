import React, { useLayoutEffect } from "react";
import { Col, Row } from "react-bootstrap";
import RadioBoxWithImage from "../../../components/inputs/radioBoxWithImage";
import OfficeImg from "./img/Office";
import OnlineImg from "./img/Online";
import { useFormikContext } from "formik";
import { IEntity, IEventPayment, TicketTypeEnum } from "../../../types";
import { useTranslation } from "react-i18next";
import TicketOffice from "./TicketOffice";
import TicketOnline from "./TicketOnline";
import { InfoCircle } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import useGetUrlLocale from "../../../hooks/useGetUrlLocale";

interface IPaymentTypesProps {
  handleShowBankAccоuntInfo: (isShow: boolean) => void;
  eventPayment: IEventPayment;
  handleUpdateEntity: (values: IEntity, currentStep: number) => Promise<void>;
  eventPaymentsLoading: boolean;
}
export default function PaymentTypes({
  handleShowBankAccоuntInfo,
  eventPayment,
  handleUpdateEntity,
  eventPaymentsLoading,
}: IPaymentTypesProps) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<IEntity>();
  const navigate = useNavigate();
  const urlLocale = useGetUrlLocale();

  const isDisableOnlinePayments =
    !values.isOnline &&
    values.tickets.every(
      (ticket) =>
        (ticket.ticketType === TicketTypeEnum.Free && ticket.active === true) ||
        (ticket.ticketType !== TicketTypeEnum.Free && ticket.active === false)
    );

  const isSkipStep =
    values.isOnline &&
    values.tickets.every(
      (ticket) =>
        (ticket.ticketType === TicketTypeEnum.Free && ticket.active === true) ||
        (ticket.ticketType !== TicketTypeEnum.Free && ticket.active === false)
    );

  useLayoutEffect(() => {
    if (isDisableOnlinePayments) {
      setFieldValue("isPayOnline", false);
    }
    if (isSkipStep) {
      navigate(`${urlLocale}/check-publish`);
      handleUpdateEntity(values, 3);
    }
  }, [isDisableOnlinePayments]);

  return (
    <>
      <div className="fw-bold fs-4 mb-3">{t("thirdStep.paymnetTypes")}</div>
      <div className="bg-light-gray text-info p-3 rounded d-flex mb-3">
        <div>
          <InfoCircle className="pe-2 fs-4" height={26} width={26} />
        </div>
        {t("thirdStep.paymentInfo")}
      </div>
      <Row className="gx-3 gy-2 mb-4">
        <Col xs={12} md={6}>
          <RadioBoxWithImage
            label={t("thirdStep.ticketOfice")}
            name="eventFormat"
            id="inline-radio-offline"
            img={<OfficeImg />}
            checked={!values.isPayOnline}
            onChange={() => setFieldValue("isPayOnline", false)}
            disabled={values.isOnline}
          />
        </Col>
        <Col xs={12} md={6}>
          <RadioBoxWithImage
            label={t("thirdStep.payOnline")}
            name="eventFormat"
            id="inline-radio-online"
            img={<OnlineImg />}
            checked={values.isPayOnline}
            onChange={() => setFieldValue("isPayOnline", true)}
            disabled={isDisableOnlinePayments}
          />
        </Col>
      </Row>
      {values.isPayOnline ? (
        <TicketOnline
          handleShowBankAccоuntInfo={handleShowBankAccоuntInfo}
          eventPayment={eventPayment}
          handleUpdateEntity={handleUpdateEntity}
          eventPaymentsLoading={eventPaymentsLoading}
        />
      ) : (
        <TicketOffice />
      )}
    </>
  );
}
