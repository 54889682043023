import classNames from "classnames";
import { ErrorMessage, useField } from "formik";
import React from "react";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface IInputPhoneProps extends PhoneInputProps {
  name: string;
  country?: string;
}
export default function InputPhone({
  name,
  country = "us",
  ...props
}: IInputPhoneProps) {
  const [, meta] = useField(name);
  return (
    <>
      <div className="d-flex flex-column w-100">
        <PhoneInput
          {...props}
          country={country}
          enableLongNumbers={true}
          containerClass={classNames("react-tel-input rounded", {
            "border border-success": meta.touched && !meta.error,
            "border border-danger": meta.touched && !!meta.error,
          })}
          inputClass={classNames("form-control w-100", {})}
          inputStyle={{ height: "58px", fontSize: "16px" }}
        />
        <ErrorMessage
          name={name}
          component="div"
          className="text-danger error-field"
        />
      </div>
    </>
  );
}
