import React from "react";
import { ChevronRight } from "react-bootstrap-icons";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import useCurrrentBreakPoint from "../../hooks/useCurrrentBreakPoint";
interface IStepPaginationProps {
  step: number;
}

interface IStepInfo {
  label?: string;
  value: number;
  currentStep: number;
}

const StepInfo = ({ currentStep, label, value }: IStepInfo) => {
  return (
    <div className="d-flex align-items-center">
      <div
        className={classnames(
          "d-flex align-items-center justify-content-center rounded-circle d-md-none fs-7",
          {
            "border border-success text-success": value === currentStep,
            "bg-sec-background text-white": value > currentStep,
            "border border-success text-white bg-success": value < currentStep,
          }
        )}
        style={{ width: 20, height: 20 }}
        data-testid="step"
      >
        {value}
      </div>
      <div
        className={classnames(
          "d-md-flex align-items-center justify-content-center rounded-circle text-white fw-bold me-2 d-none",
          {
            "bg-success": value <= currentStep,
            "bg-sec-background": value > currentStep,
          }
        )}
        style={{ width: 26, height: 26 }}
        data-testid="step"
      >
        {value}
      </div>
      <div
        className={classnames("text-nowrap d-none d-md-block", {
          "fw-bold": value <= currentStep,
          "text-sec-background": value > currentStep,
        })}
      >
        {label}
      </div>
    </div>
  );
};

const StepArrow = ({ currentStep, value }: IStepInfo) => {
  const { currentBreakpoint } = useCurrrentBreakPoint();

  const size =
    currentBreakpoint === "xs" || currentBreakpoint === "sm" ? 10 : 16;

  return (
    <ChevronRight
      height={size}
      width={size}
      className={classnames({
        "text-sec-background": currentStep < value,
      })}
    />
  );
};

export default function StepPagination({ step }: IStepPaginationProps) {
  const { t } = useTranslation();
  return (
    <div
      className="p-3 border border-sec-background d-flex justify-content-evenly align-items-center rounded-3 mb-4 mb-md-4"
      style={{
        background: "#FAFBFF",
        overflowX: "auto",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      <StepInfo currentStep={step} value={1} label={t("step.event")} />
      <StepArrow currentStep={step} value={1} />
      <StepInfo currentStep={step} value={2} label={t("step.tickets")} />
      <StepArrow currentStep={step} value={2} />
      <StepInfo currentStep={step} value={3} label={t("step.payment")} />
      <StepArrow currentStep={step} value={3} />
      <StepInfo currentStep={step} value={4} label={t("step.check")} />
    </div>
  );
}
