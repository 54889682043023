import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import { TicketCalendar } from "@me-ticket/calendar";

interface ICalendarModalProps {
  handleClose: () => void;
}
export default function CalendarModal({ handleClose }: ICalendarModalProps) {
  const { t } = useTranslation();

  return (
    <Modal show={true} onHide={handleClose} centered size="xl">
      <Modal.Header closeButton className="border-0">
        <Modal.Title>{t("firstStep.Preview Calendar")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-hidden">
        {/* <TicketCalendar
          // axiosInstance={axiosInstance}
          // timeZone={"Europe/Warsaw"}
          socketUrl={"wss://ws.me-pos.com/"}
          companyId={459}
          apiUrl={"https://me-ticket.com"}
        /> */}
      </Modal.Body>
    </Modal>
  );
}
