import React from "react";
const NonReturnable = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={46} height={49} fill="none">
    <path
      fill="#16B054"
      d="m42.027 5.809-2.11-.531c-1.245 1.487-3.431 2.207-5.586 1.664C32.177 6.4 30.675 4.751 30.4 2.88l-2.108-.53c-1.677-.423-3.386.447-3.818 1.942L14.229 39.73c-.432 1.495.577 3.05 2.253 3.472l2.167.546c1.27-1.392 3.392-2.049 5.483-1.522 2.09.527 3.57 2.09 3.917 3.89l2.168.545c1.676.423 3.385-.447 3.817-1.943L44.28 9.281c.432-1.495-.576-3.05-2.252-3.471v-.001ZM25.16 9.942c-.608-.153-.973-.716-.817-1.258.157-.542.776-.858 1.384-.705.608.154.974.717.817 1.259-.157.542-.777.857-1.384.704Zm3.082.777c-.608-.154-.973-.717-.817-1.26.157-.541.777-.856 1.384-.704.608.153.974.717.817 1.259-.157.542-.776.858-1.384.705Zm3.082.776c-.608-.153-.973-.717-.816-1.259.157-.542.776-.857 1.384-.704.608.153.973.716.816 1.259-.157.541-.776.857-1.384.704Zm3.082.776c-.607-.153-.972-.716-.816-1.258.157-.543.776-.858 1.384-.705.607.153.973.716.816 1.259-.157.542-.776.857-1.384.704Zm3.083.777c-.608-.153-.973-.717-.817-1.259.157-.542.776-.857 1.384-.704.608.153.974.716.817 1.258-.157.542-.776.858-1.384.705Zm3.082.776c-.608-.153-.973-.716-.816-1.258.156-.543.776-.858 1.383-.705.608.153.974.716.817 1.259-.157.542-.776.857-1.384.704Z"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="m33.154 3.897.13.008c1.657.092 2.86 1.367 2.73 2.818L32.68 43.459c-.133 1.456-1.562 2.589-3.222 2.496l-2.137-.12c-.731-1.714-2.511-2.959-4.68-3.08-2.17-.121-4.154.916-5.182 2.53l-2.143-.12c-1.657-.093-2.86-1.368-2.73-2.818L15.92 5.61c.132-1.456 1.563-2.588 3.222-2.496l2.072.116c.676 1.799 2.496 3.123 4.73 3.248 2.236.124 4.273-.986 5.262-2.69l1.948.109ZM17.51 11.163c.692.039 1.323-.434 1.383-1.09.06-.662-.487-1.205-1.182-1.244-.692-.039-1.323.434-1.383 1.09-.06.662.487 1.205 1.182 1.244Zm3.173.177c.693.04 1.324-.434 1.383-1.09.06-.662-.487-1.206-1.181-1.244-.692-.039-1.323.434-1.383 1.09-.06.662.487 1.206 1.181 1.244Zm3.174.178c.692.038 1.323-.435 1.383-1.09.06-.662-.487-1.206-1.181-1.245-.693-.038-1.324.435-1.383 1.09-.06.662.486 1.206 1.181 1.245Zm1.992-1.067c-.06.661.487 1.205 1.181 1.244l.009-.15-.009.15c.693.039 1.324-.435 1.383-1.09.06-.662-.487-1.206-1.181-1.244-.692-.04-1.323.434-1.383 1.09Zm3.174.177c-.06.662.487 1.206 1.181 1.245.693.038 1.323-.435 1.383-1.09.06-.663-.487-1.206-1.181-1.245-.693-.039-1.324.434-1.383 1.09Zm4.355 1.422c.692.038 1.323-.435 1.383-1.09.06-.662-.487-1.206-1.182-1.245-.692-.038-1.323.435-1.383 1.09v.001c-.06.661.487 1.205 1.182 1.244Z"
    />
    <path
      fill="#F44336"
      stroke="#3E4957"
      strokeWidth={0.5}
      d="m7.088 35.43-.007.006-.006.006c-.22.209-.467.308-.753.308s-.534-.1-.753-.308c-.22-.209-.319-.438-.319-.698 0-.26.1-.489.319-.698l.007-.006 7.375-7.57a2.25 2.25 0 0 0-.037-3.179L5.57 16.105l-.002-.002c-.22-.21-.319-.438-.319-.698 0-.26.1-.49.319-.698l-.172-.181.172.181c.422-.401.786-.469 1.026-.455a.95.95 0 0 1 .367.096c.002 0 .003.002.005.003l8.06 7.16a2.25 2.25 0 0 0 2.961.024l7.928-6.82.01-.008c.219-.209.466-.308.752-.308s.534.1.753.308c.22.208.319.438.319.698 0 .26-.1.489-.319.698l-.005.005-7.142 7.206a2.25 2.25 0 0 0-.037 3.13l7.175 7.593.01.01c.219.208.318.437.318.697 0 .26-.1.49-.319.698-.22.209-.467.308-.753.308s-.533-.1-.753-.308l-.003-.003-7.482-6.893a2.25 2.25 0 0 0-2.961-.077L7.088 35.43Z"
    />
  </svg>
);
export default NonReturnable;
