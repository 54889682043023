import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { Col, Row, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InputDateSmall from "../../../components/inputs/inputDateSmall";
import InputNumberSmall from "../../../components/inputs/inputNumberSmall";
import SelectSmall from "../../../components/inputs/selectSmall";
import {
  EndRecurrenceEnum,
  IEntity,
  RepeatPeriodEnum,
  SelectOption,
} from "../../../types";
import CalendarModal from "../modal/CalendarModal";

export default function CustomRecurrenceSettings() {
  const { t } = useTranslation();
  const WEEK_DAYS = [
    t("firstStep.mon"),
    t("firstStep.tue"),
    t("firstStep.wed"),
    t("firstStep.thu"),
    t("firstStep.fri"),
    t("firstStep.sat"),
    t("firstStep.sun"),
  ];
  const REPEATS = [
    t("firstStep.daily"),
    t("firstStep.weekly"),
    t("firstStep.monthly"),
    t("firstStep.yearly"),
  ];
  const repeatsValues = [
    RepeatPeriodEnum.Daily,
    RepeatPeriodEnum.Weekly,
    RepeatPeriodEnum.Monthly,
    RepeatPeriodEnum.Yearly,
  ];
  const { setFieldValue, values } = useFormikContext<IEntity>();
  const [showModal, setShowModal] = useState(false);

  const repeatsOptions = REPEATS.map((item, index) => ({
    label: item,
    value: repeatsValues[index],
  }));

  const weekDaysOptions = WEEK_DAYS.map((item, index) => ({
    label: item,
    value: values.recurrence.weekDays[index],
  }));

  return (
    <>
      {showModal && <CalendarModal handleClose={() => setShowModal(false)} />}
      <div className="border rounded p-4 mt-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="fw-bold fs-5">
            {t("firstStep.customRecurrenceSettings")}
          </div>
          {/* <Button
            variant="link"
            className="text-success"
            onClick={() => setShowModal(true)}
          >
            {t("firstStep.viewCalendar")}
          </Button> */}
        </div>
        <Row>
          <Col md={6}>
            <div className="fw-bold fs-8 mb-3">{t("firstStep.repeats")}</div>
            <Row className="alingn-items-center mb-3">
              <Col>
                <div className="h-100 d-flex align-items-center">
                  {t("firstStep.repeatsEvery")}
                </div>
              </Col>
              <Col>
                <InputNumberSmall
                  type="number"
                  min={1}
                  name="recurrence.repeatsEvery"
                />
              </Col>
              <Col>
                <SelectSmall
                  options={repeatsOptions}
                  name="recurrence.repeatsType"
                  value={repeatsOptions.find(
                    (option) => option.value === values.recurrence.repeatsType
                  )}
                  onChange={(selectedOption: SelectOption | null) => {
                    setFieldValue(
                      "recurrence.repeatsType",
                      selectedOption
                        ? selectedOption.value
                        : RepeatPeriodEnum.Daily
                    );
                  }}
                />
              </Col>
            </Row>

            {values.recurrence.repeatsType === RepeatPeriodEnum.Weekly && (
              <>
                <div className="mb-2">{t("firstStep.repeatOn")}</div>
                <div className="d-flex justify-content-between">
                  {weekDaysOptions.map((item, index) => (
                    <Button
                      variant={
                        item.value ? "outline-success" : "outline-light-blue"
                      }
                      key={item.label}
                      className={classNames(
                        "rounded-5 fs-7 p-0 d-flex justify-content-center align-items-center fw-light",
                        {
                          "bg-success-bg text-success": item.value,
                        }
                      )}
                      style={{ width: 50, height: 32 }}
                      onClick={() =>
                        setFieldValue(
                          "recurrence.weekDays",
                          values.recurrence.weekDays.map((el, i) =>
                            i === index ? !el : el
                          )
                        )
                      }
                    >
                      {item.label}
                    </Button>
                  ))}
                </div>
              </>
            )}
          </Col>
          <Col md={6}>
            <div className="fw-bold fs-8 mb-3">{t("firstStep.end")}</div>
            <Row>
              <Col>
                <Form.Check
                  type="radio"
                  id="end-recurrence-neverStop"
                  label={t("firstStep.neverStop")}
                  value={EndRecurrenceEnum.NeverStop}
                  name="recurrence.endRecurrence"
                  checked={
                    values.recurrence.endRecurrence ===
                    EndRecurrenceEnum.NeverStop
                  }
                  onChange={(e) =>
                    setFieldValue("recurrence.endRecurrence", e.target.value)
                  }
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex align-items-center">
                <Form.Check
                  type="radio"
                  id="end-recurrence-onDate"
                  label={t("firstStep.onDate")}
                  value={EndRecurrenceEnum.OnDate}
                  name="recurrence.endRecurrence"
                  checked={
                    values.recurrence.endRecurrence === EndRecurrenceEnum.OnDate
                  }
                  onChange={(e) =>
                    setFieldValue("recurrence.endRecurrence", e.target.value)
                  }
                />
              </Col>
              <Col xs={8}>
                <InputDateSmall
                  name="recurrence.onDate"
                  disabled={
                    values.recurrence.endRecurrence !== EndRecurrenceEnum.OnDate
                  }
                  min={new Date(values.startDate).toISOString().split("T")[0]}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex align-items-center">
                <Form.Check
                  type="radio"
                  id="end-recurrence-after"
                  label={t("firstStep.after")}
                  value={EndRecurrenceEnum.After}
                  name="recurrence.endRecurrence"
                  checked={
                    values.recurrence.endRecurrence === EndRecurrenceEnum.After
                  }
                  onChange={(e) =>
                    setFieldValue("recurrence.endRecurrence", e.target.value)
                  }
                />
              </Col>
              <Col xs={8}>
                <div className="position-relative">
                  <InputNumberSmall
                    type="number"
                    min={1}
                    name="recurrence.endAfterOccurences"
                    disabled={
                      values.recurrence.endRecurrence !==
                      EndRecurrenceEnum.After
                    }
                  />
                  <div
                    className="position-absolute end-0 translate-middle-y"
                    style={{ marginRight: 33, top: 20 }}
                  >
                    {t("firstStep.occurrence")}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
