import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IEntity, SelectOption } from "../../../../types";
import Stripe from "../img/Stripe";
import InputText from "../../../../components/inputs/inputText";
import SelectInput from "../../../../components/inputs/select";
import {
  useCreateOrUpdatePaymentStripeAccountMutation,
  useGetCountriesQuery,
  useGetStripeCountriesQuery,
} from "../../../../qraphql/event.hooks";
import { useFormikContext } from "formik";
import ApolloError from "../../../../components/apolloError";
import useGetUrlLocale from "../../../../hooks/useGetUrlLocale";

interface ICountryModalProps {
  handleClose: () => void;
  handleSave: (isStripeConnect: boolean) => void;
}

export default function CountryModal({
  handleClose,
  handleSave,
}: ICountryModalProps) {
  const { t } = useTranslation();
  const urlLocale = useGetUrlLocale();
  const { values, setFieldValue } = useFormikContext<IEntity>();
  const { data: countriesData } = useGetCountriesQuery();
  const { data: stripeCountriesData } = useGetStripeCountriesQuery();
  const [
    createOrUpdatePaymentStripeAccount,
    { data: paymentStripeAccountData, error: paymentStripeAccountError },
  ] = useCreateOrUpdatePaymentStripeAccountMutation();

  const optionsCountries =
    countriesData?.countries
      .map((item) => ({
        value: item.id.toString(),
        label: item.country,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const handleSubmit = async () => {
    if (!values.stripe.country || !values.stripe.email) return;
    const stripeCountry = stripeCountriesData?.stripeCountries.find(
      (item) => item.id.toString() === values.stripe.country
    );
    if (!stripeCountry) {
      handleSave(false);
    } else {
      try {
        await createOrUpdatePaymentStripeAccount({
          variables: {
            eventId: values.id,
            returnUrl: `${process.env.REACT_APP_TICKET_ADMIN_URL}${urlLocale}/payment-info/connect/${values.id}`,
            input: {
              countryId: Number(values.stripe.country),
              email: values.stripe.email,
            },
          },
        });
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (paymentStripeAccountData) {
      handleSave(true);
      window.location.href =
        paymentStripeAccountData.createOrUpdatePaymentStripeAccount.redirectUrl;
    }
  }, [paymentStripeAccountData, handleSave]);

  return (
    <>
      <Modal show={true} onHide={handleClose} centered>
        <Modal.Header closeButton className="border-0">
          <Modal.Title>
            <Stripe />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="text-center mb-3">{t("thirdStep.enterStripe")}</div>

          <InputText placeholder="Email" name="stripe.email" className="mb-3" />
          <SelectInput
            label="Country"
            name="stripe.country"
            className="mb-3"
            placeholder={t("firstStep.selectCountry")}
            options={optionsCountries}
            onChange={(selectedOption: SelectOption | null) => {
              setFieldValue(
                "stripe.country",
                selectedOption ? selectedOption.value : ""
              );
            }}
            value={optionsCountries.find(
              (option) => option.value === values.stripe.country
            )}
          />
          <Button
            variant="stripe"
            className="fw-bold py-3 px-5 w-100"
            onClick={handleSubmit}
          >
            {t("firstStep.save")}
          </Button>
          {/* <div className="px-3">
            <div className="text-center mb-3 text-info">
              {t("thirdStep.savedStripe")}
            </div>
            <div className="mb-3">{t("thirdStep.email")} </div>
            <div className="mb-3">{t("thirdStep.selectedCountry")} </div>
            <Button
              variant="stripe"
              className="fw-bold py-3 px-5 w-100"
              onClick={() => {}}
            >
              {t("thirdStep.continueEvent")}
            </Button>
            <div className="d-flex align-items-center py-3 px-0">
              <hr className="w-100 text-info" />
              <p className="text-info px-2 mb-0">{t("thirdStep.or")}</p>
              <hr className="w-100 text-info" />
            </div>
            <Button
              variant="outline-stripe"
              className="py-3 px-5 w-100 fw-normal"
              onClick={() => {}}
            >
              {t("thirdStep.changeStripe")}
            </Button>
          </div> */}
          <ApolloError networkError={paymentStripeAccountError?.networkError} />
        </Modal.Body>
      </Modal>
    </>
  );
}
