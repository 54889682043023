import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ImageCropModal from "../modal/ImageCropModal";
import { Plus, Pencil, Trash } from "react-bootstrap-icons";
import { ErrorMessage, useFormikContext } from "formik";
import { IEntity } from "../../../types";
import { base64ToFile } from "../../../helpers";
import { useTranslation } from "react-i18next";

export default function Poster() {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<IEntity>();
  const addPosterRef = useRef<HTMLInputElement>(null);

  const [draftUmage, setDraftImage] = useState("");
  const [imagePoster, setImagePoster] = useState("");
  const [errorSize, setErrorSize] = useState(false);

  useEffect(() => {
    if (values.poster) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePoster(reader.result as string);
      };
      reader.readAsDataURL(values.poster);
    }
  }, [values]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setErrorSize(true);
        return;
      } else {
        setErrorSize(false);
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setDraftImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
    event.target.value = null;
  };

  const handleClose = () => {
    setDraftImage("");
  };

  const handleSavePosterImg = async (newImage: string) => {
    const file = base64ToFile(newImage, "poster");
    setImagePoster(newImage);
    setFieldValue("poster", file);
    handleClose();
  };

  const handleEditPoster = () => {
    setDraftImage(imagePoster);
  };

  const handleDeletePoster = () => {
    setImagePoster("");
    setFieldValue("poster", null);
  };

  return (
    <>
      <ImageCropModal
        image={draftUmage}
        handleClose={handleClose}
        handleSave={handleSavePosterImg}
      />
      <Row className="gy-3">
        <Col xs={12} md={5}>
          <div className="fw-bold fs-8 mb-2">{t("firstStep.poster")}</div>
          <div className="text-info fs-7">{t("firstStep.posterInfo")}</div>
        </Col>
        <Col
          xs={12}
          md={7}
          className="d-flex justify-content-center d-md-block"
        >
          {imagePoster ? (
            <div
              className="position-relative rounded-3 p-1 border"
              style={{ width: 210, height: 276 }}
            >
              <img
                src={imagePoster}
                alt="img poster"
                className="img-fluid rounded-3 w-100"
              />
              <div className="position-absolute top-50 start-50 translate-middle d-flex gap-3">
                <div
                  className="rounded-circle bg-white d-flex justify-content-center align-items-center cursor-pointer"
                  style={{ width: 32, height: 32 }}
                  onClick={handleEditPoster}
                >
                  <Pencil />
                </div>
                <div
                  className="rounded-circle bg-white d-flex justify-content-center align-items-center cursor-pointer"
                  style={{ width: 32, height: 32 }}
                  onClick={handleDeletePoster}
                >
                  <Trash />
                </div>
              </div>
              <ErrorMessage
                name="poster"
                component="div"
                className="text-danger error-field"
              />
            </div>
          ) : (
            <>
              <div
                className="rounded-3 p-1 dashed-button cursor-pointer"
                style={{ width: 210, height: 276 }}
                onClick={() => addPosterRef.current?.click()}
              >
                <div className="bg-success-light h-100 rounded-3 text-success d-flex justify-content-center align-items-center flex-column">
                  <Plus className="fs-2" />
                  {t("firstStep.addPoster")}
                </div>
              </div>
              <ErrorMessage
                name="poster"
                component="div"
                className="text-danger error-field"
              />
              {errorSize && (
                <div className="text-danger error-field">
                  {t("firstStep.maxSize")} 5 MB
                </div>
              )}
              <input
                ref={addPosterRef}
                type="file"
                style={{ display: "none" }}
                accept=".png, .jpg, .jpeg, .webp"
                onChange={handleImageChange}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
