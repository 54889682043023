import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputActionMeta } from "react-select";
import SelectInput from "../../components/inputs/select";
import {
  useGetAllPerformersLazyQuery,
  useGetPerformerNameByIdLazyQuery,
} from "../../qraphql/event.hooks";
import { IEntity, SelectOption } from "../../types";
import { useDebounce } from "use-debounce";

const PERFORMERS_LIMIT = 20;

export default function Performers() {
  const { t, i18n } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [performersOptions, setPerformersOptions] = useState<SelectOption[]>(
    []
  );
  const [performersSearchOptions, setPerformersSearchOptions] = useState<
    SelectOption[]
  >([]);
  const [value, setValue] = useState<SelectOption[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const [searchValueDebounced] = useDebounce(searchValue, 500);

  const { setFieldValue, values, setFieldTouched } =
    useFormikContext<IEntity>();
  const [getPerformers, { data: performersData, loading }] =
    useGetAllPerformersLazyQuery();
  const [getPerformerNameById, { loading: getPerformerNameByIdLoading }] =
    useGetPerformerNameByIdLazyQuery();

  useEffect(() => {
    // list performers request on first page load
    if (
      (values.performers.length > 0 || values.newPerformers.length > 0) &&
      value.length === 0
    ) {
      const promises = values.performers.map((performerId: string) =>
        getPerformerNameById({ variables: { id: Number(performerId) } })
      );
      Promise.all(promises)
        .then((results) => {
          const performerOptions = results.map((result) => ({
            value: result.data.performerById.id.toString(),
            label: result.data.performerById.name,
          }));
          setValue([
            ...performerOptions,
            ...values.newPerformers.map((item, i) => ({
              value: `new-${i}`,
              label: item,
            })),
          ]);
        })
        .catch(() => {});
    } else {
      setValue((prev) =>
        prev.map((item) =>
          item.label.startsWith(`${t("firstStep.Add new performer")} - `)
            ? { ...item, label: item.label.split(" - ")[1] }
            : item
        )
      );
    }
    // eslint-disable-next-line
  }, [values.performers, values.newPerformers, i18n.language]);

  useEffect(() => {
    handleMenuScrollToBottom();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!searchValueDebounced) return;
    getPerformers({ variables: { page: 1, search: searchValueDebounced } });
    // eslint-disable-next-line
  }, [searchValueDebounced]);

  useEffect(() => {
    if (!performersData || searchValueDebounced.length) return;
    setPerformersOptions((prev) => [
      ...prev,
      ...performersData.performersProxy.items.map((type) => ({
        value: type.id.toString(),
        label: type.name,
      })),
    ]);
    setTotalPages(Number(performersData.performersProxy.pageCount));
    setCurrentPage(performersData.performersProxy.currentPage + 1);
  }, [performersData, i18n.language]);

  useEffect(() => {
    if (!performersData || !searchValueDebounced.length) return;
    setPerformersSearchOptions(
      performersData.performersProxy.items.map((type) => ({
        value: type.id.toString(),
        label: type.name,
      }))
    );
    if (
      searchValueDebounced.length >= 3 &&
      searchValueDebounced.length <= 255 &&
      !performersData.performersProxy.items.find(
        (item) =>
          item.name.toLocaleLowerCase() ===
          searchValueDebounced.trim().toLocaleLowerCase()
      )
    ) {
      setPerformersSearchOptions((prev) => [
        {
          value: `new-${Date.now()}`,
          label: `${t("firstStep.Add new performer")} - ${searchValueDebounced}`,
        },
        ...prev,
      ]);
    }
  }, [performersData, i18n.language]);

  const handleChange = (selectedOption: SelectOption[] | null) => {
    selectedOption = selectedOption.slice(0, PERFORMERS_LIMIT);
    setSearchValue("");
    setFieldValue(
      "performers",
      selectedOption
        ? selectedOption
            .filter((item) => !item.value.startsWith("new-"))
            .map((item) => item.value)
        : []
    );
    setFieldValue(
      "newPerformers",
      selectedOption.some((item) => item.value.startsWith("new-"))
        ? selectedOption
            .filter((item) => item.value.startsWith("new-"))
            .map((item) =>
              item.label.startsWith(`${t("firstStep.Add new performer")} - `)
                ? item.label.split(" - ")[1]
                : item.label
            )
        : []
    );
    setValue(selectedOption);
  };

  const handleMenuScrollToBottom = async () => {
    if (!loading && currentPage <= totalPages) {
      await getPerformers({ variables: { page: currentPage } });
    }
  };

  const handleInputChange = async (
    inputValue: string,
    actionMeta: InputActionMeta
  ) => {
    if (actionMeta.action === "input-change") {
      setSearchValue(inputValue);
    }
  };

  return (
    <SelectInput
      options={
        searchValueDebounced.length
          ? performersSearchOptions
          : performersOptions
      }
      placeholder={t("firstStep.selectPerformer")}
      label={t("firstStep.performer")}
      name="performers"
      isMulti
      className="mb-3"
      onChange={handleChange}
      value={value.map((item) =>
        item.value.startsWith("new-")
          ? {
              value: item.value,
              label: item.label.startsWith(
                `${t("firstStep.Add new performer")} - `
              )
                ? item.label.split(" - ")[1]
                : item.label,
            }
          : item
      )}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      onInputChange={handleInputChange}
      inputValue={searchValue}
      onBlur={() => setFieldTouched("performers", true)}
      isLoading={getPerformerNameByIdLoading}
    />
  );
}
