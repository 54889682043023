import React from "react";
const OnlineEvent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={33} height={46} fill="none">
    <path
      fill="#fff"
      d="M5.542 25.193c-.164.143-1.462 1.245-3.077.873-.775-.178-2.027-.789-2.183-1.892-.105-.737.33-1.336.41-1.442.485-.645 1.17-.745 1.853-1.006.722-.277 1.687-.827 2.673-2.038l.324 5.505Z"
    />
    <path
      fill="#3E4957"
      d="M3.135 26.228a3.07 3.07 0 0 1-.69-.078c-.756-.174-2.081-.784-2.248-1.964-.105-.733.3-1.337.426-1.505.4-.53.916-.703 1.466-.886.139-.046.283-.094.424-.148.964-.37 1.852-1.046 2.638-2.012l.138-.17.34 5.764-.032.028c-.208.182-1.183.97-2.462.97Zm2.01-6.321c-.771.902-1.635 1.54-2.571 1.898-.145.056-.29.104-.432.151-.522.175-1.017.34-1.384.827-.116.155-.488.71-.393 1.38.154 1.09 1.453 1.668 2.118 1.821 1.47.34 2.673-.576 2.97-.828l-.308-5.25Z"
    />
    <path
      fill="#fff"
      d="M5.542 19.689c-.164.143-1.462 1.245-3.077.873-.775-.178-2.027-.789-2.183-1.892-.105-.737.33-1.336.41-1.442.485-.645 1.17-.745 1.853-1.006.722-.277 1.687-.827 2.673-2.038l.324 5.505Z"
    />
    <path
      fill="#3E4957"
      d="M3.135 20.724a3.07 3.07 0 0 1-.69-.078c-.756-.174-2.081-.784-2.248-1.964-.105-.733.3-1.337.426-1.505.4-.53.916-.703 1.466-.886.139-.046.283-.094.424-.148.964-.37 1.852-1.046 2.638-2.012l.138-.17.34 5.764-.032.028c-.208.183-1.182.971-2.462.971Zm2.01-6.321c-.771.902-1.634 1.54-2.571 1.898-.145.056-.29.104-.432.152-.522.174-1.017.339-1.384.826-.116.155-.488.712-.393 1.38.154 1.09 1.453 1.668 2.118 1.821 1.47.34 2.673-.576 2.97-.828l-.308-5.249Z"
    />
    <path
      fill="#fff"
      d="M6.26 10.2c-.752-.157-4.226-.952-5.278-3.547-.17-.42-.746-1.837-.06-2.65.452-.538 1.243-.559 1.39-.561.807-.012 1.306.467 1.931.848.662.4 1.688.826 3.25.862-.41 1.682-.82 3.365-1.232 5.048Z"
    />
    <path
      fill="#3E4957"
      d="m6.322 10.3-.08-.016a10.814 10.814 0 0 1-2.548-.867C2.294 8.723 1.355 7.804.9 6.686a5.444 5.444 0 0 1-.355-1.197c-.102-.657 0-1.175.309-1.54.438-.52 1.163-.587 1.455-.591.662-.01 1.117.293 1.598.614.122.082.248.166.378.245.882.535 1.961.82 3.207.848l.105.002-1.276 5.234ZM2.34 3.528h-.028c-.268.004-.934.064-1.327.53-.432.515-.407 1.377.074 2.565C2 8.947 4.904 9.822 6.196 10.1l1.188-4.868c-1.232-.043-2.303-.336-3.186-.871a10.85 10.85 0 0 1-.384-.248c-.451-.302-.878-.587-1.474-.587Z"
    />
    <path
      fill="#3E4957"
      d="M20.173.254 7.746.187a3.082 3.082 0 0 0-3.099 3.065l-.162 30.024a3.082 3.082 0 0 0 3.066 3.1l12.426.067a3.082 3.082 0 0 0 3.099-3.066l.162-30.024a3.082 3.082 0 0 0-3.065-3.1Z"
    />
    <g clipPath="url(#a)">
      <path
        fill="#DDE0E4"
        d="M19.385 1.147 8.592 1.088c-1.816-.01-3.295 1.467-3.305 3.298L5.14 31.86c-.01 1.831 1.453 3.324 3.269 3.334l10.793.058c1.815.01 3.295-1.466 3.305-3.298l.147-27.473c.01-1.831-1.454-3.324-3.27-3.334Z"
      />
      <g clipPath="url(#b)">
        <path fill="#F3F6FD" d="M-4-.83h37v37H-4z" />
        <path
          fill="#E5FAED"
          stroke="#3E4957"
          strokeWidth={0.5}
          d="M36.11 16.547c0 11.318-9.175 20.492-20.493 20.492-11.317 0-20.492-9.174-20.492-20.492C-4.875 5.23 4.3-3.945 15.617-3.945c11.318 0 20.493 9.174 20.493 20.492Z"
        />
        <path
          fill="#fff"
          stroke="#3E4957"
          strokeWidth={0.2}
          d="M23.016 24.63c8.031 2.82 14.364 8.717 16.67 11.47H-14.57c.607-.54 1.58-1.297 2.736-1.996 1.286-.778 2.791-1.48 4.264-1.737.923-.161 2.207-.208 3.644-.236l.895-.016c1.18-.02 2.426-.041 3.623-.113 1.503-.089 2.938-.258 4.08-.607 1.138-.348 2.011-.882 2.351-1.721a10.02 10.02 0 0 1 4.644-5.046c2.556-1.314 6.235-1.795 11.349.001ZM36.166-5.795h14.429v36.769c-3.314-.858-10.634-2.84-14.423-4.165-2.373-.83-4.279-2.67-5.574-4.828-1.295-2.16-1.973-4.629-1.899-6.706.04-1.095-.55-1.909-1.37-2.598-.568-.477-1.26-.904-1.944-1.327-.297-.183-.593-.366-.876-.551-.947-.62-1.763-1.272-2.128-2.111-.36-.829-.289-1.864.6-3.275C24.413 3.143 26.91.8 29.456-1.208c2.533-1.999 5.107-3.661 6.711-4.586Z"
        />
        <path
          fill="#fff"
          stroke="#3E4957"
          strokeWidth={0.2}
          d="m51.204 17.622-.324 2.433c-1.255.279-4.006.199-5.533-2.256a61.902 61.902 0 0 1-.877-1.467c-.17-.29-.337-.577-.505-.857-.442-.737-.884-1.424-1.372-2.009-.979-1.172-2.149-1.935-3.888-1.87-.447.016-.954.194-1.503.485-.55.29-1.15.699-1.784 1.185-1.21.926-2.554 2.143-3.93 3.388l-.2.182c-1.448 1.31-2.925 2.637-4.318 3.687-1.396 1.053-2.694 1.816-3.785 2.013-1.093.197-2.73-.053-4.632-.555-1.899-.501-4.046-1.25-6.153-2.037-1.21-.453-2.405-.917-3.53-1.355-.834-.324-1.63-.634-2.368-.913-1.724-.653-3.129-1.142-3.908-1.246l-.22-.03c-1.435-.194-2.712-.367-4.154.094-1.516.485-3.2 1.664-5.456 4.215-1.807 2.043-4.353 5.924-6.55 9.533-2.16 3.544-3.99 6.84-4.463 7.892l-1.55.113c1.957-3.22 5.813-9.563 9.984-14.83 2.092-2.643 4.26-5.009 6.303-6.575 2.05-1.572 3.945-2.315 5.505-1.777 1.67.575 3.37 1.283 5.089 2l1.103.458c2.093.865 4.209 1.702 6.32 2.283 4.223 1.162 8.438 1.302 12.423-1.418 2.595-1.77 4.828-3.575 6.73-5.113.993-.803 1.896-1.534 2.715-2.149 1.197-.9 2.207-1.549 3.057-1.832.423-.141.8-.189 1.137-.136.335.053.637.208.91.484.817.826 1.678 1.942 2.547 3.069.347.449.694.9 1.04 1.334.608.761 1.21 1.472 1.796 2.033.584.559 1.158.978 1.708 1.14.848.252 1.485.367 1.938.407a3.6 3.6 0 0 0 .547.013c.056-.004.107-.009.151-.016Z"
        />
        <path
          fill="#16B054"
          stroke="#3E4957"
          strokeWidth={0.2}
          d="M-22.25 36.1c1.633-3.132 4.533-8.127 7.934-12.213 1.727-2.075 3.58-3.91 5.457-5.15 1.879-1.238 3.77-1.872 5.583-1.564 1.9.323 3.851.765 5.824 1.21.42.096.842.192 1.265.286 2.4.537 4.821 1.045 7.199 1.319 4.754.546 9.35.155 13.282-2.834 2.564-1.948 4.728-3.883 6.572-5.531.963-.861 1.84-1.645 2.64-2.31 1.17-.975 2.175-1.698 3.06-2.065.88-.364 1.627-.37 2.298.069l.055-.084-.055.084c1.009.658 2.107 1.578 3.216 2.507.441.37.884.742 1.324 1.099.77.624 1.53 1.203 2.246 1.646.716.442 1.399.754 2.015.836.957.126 1.664.152 2.16.134.248-.01.444-.03.594-.056.067-.01.125-.024.175-.037v2.264c-.648.202-1.708.371-2.86.243-1.192-.132-2.476-.581-3.504-1.635a60.6 60.6 0 0 1-1.164-1.24c-.228-.247-.452-.49-.675-.726-.588-.624-1.168-1.201-1.783-1.678-1.234-.957-2.61-1.51-4.481-1.237-.479.07-1.001.294-1.556.629-.555.335-1.147.784-1.766 1.309-1.18 1.001-2.466 2.286-3.78 3.601l-.192.192c-1.383 1.382-2.796 2.785-4.158 3.922-1.364 1.14-2.666 2.003-3.828 2.32-1.165.318-3.037.307-5.258.106-2.215-.201-4.763-.59-7.271-1.02-1.517-.26-3.016-.534-4.42-.79-.917-.168-1.794-.328-2.606-.472-2.048-.362-3.694-.62-4.551-.62-.348 0-.683-.006-1.01-.013-1.246-.027-2.357-.05-3.524.342-1.478.497-3.041 1.657-5.124 4.281-3.296 4.154-5.896 10.877-6.578 12.876h-2.754Z"
        />
        <path
          fill="#fff"
          stroke="#3E4957"
          strokeWidth={0.2}
          d="M-24.233-3.55C-27.385-5.33-30.71-6.86-33.02-7.61H2.877c1.12.395 3.674 1.734 6.067 3.582 1.201.928 2.359 1.982 3.272 3.106.914 1.126 1.58 2.316 1.805 3.517.458 2.44.552 4.19.486 5.383a7.11 7.11 0 0 1-.194 1.386c-.089.336-.19.538-.27.636h-.001l-.006.008-2.13 3.161c-3.051.398-10.456.944-16.061-.005-3.504-.593-5.544-2.1-7.17-4.03-.814-.968-1.525-2.043-2.265-3.168l-.049-.075c-.724-1.1-1.478-2.247-2.383-3.377-1.487-1.858-4.754-4.116-8.21-6.066Z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M5.848 5.904c.07 0 .128.065.128.146v.742c0 .08-.057.145-.128.145-.072 0-.13-.065-.13-.145V6.05c0-.08.058-.146.13-.146ZM5.848 7.453c.07 0 .128.065.128.145v.743c0 .08-.057.145-.128.145-.072 0-.13-.065-.13-.145v-.743c0-.08.058-.145.13-.145ZM7.179 7.153c0 .07-.065.129-.145.129h-.74c-.08 0-.146-.058-.146-.13 0-.07.065-.129.145-.129h.741c.08 0 .145.058.145.13ZM5.632 7.153c0 .07-.065.129-.145.129h-.74c-.08 0-.145-.058-.145-.13 0-.07.064-.129.144-.129h.741c.08 0 .145.058.145.13Z"
          clipRule="evenodd"
          opacity={0.1}
        />
        <path
          fill="#3E4957"
          d="M10.532 19.612c.723-.637 2.163-1.476 2.792-1.816-.5.079-1.708.19-2.542 0-.833-.19-1.542-.927-1.792-1.272-.481-.07-1.53-.497-1.875-1.648-.345-1.151.004-2.323.222-2.766-.176-.186-.422-.866 0-2.095.422-1.23 1.482-1.602 1.959-1.635-.18-.079-.053-.452 1.903-1.313 1.956-.86 3.353-.125 3.807.35.583-.22 2.009-.056 3.042 2.346 1.034 2.403-.217 4.41-.972 5.113.065.13.214.439.292.629.096.237.124.377-.153.544-.278.168-1.084.405-1.348.475-.264.07-.25.042-.25.35 0 .245.093.773.139 1.005l1.82.825 3.39 5 6.376-.796.153-.517c.005-.577.025-1.754.07-1.843.044-.09.185.512.25.824l.458-1.425.333.545c.075.517.206 1.785.14 2.723-.067.94-.77.746-1.112.531l-6.849 1.984-3.057-2.948-5.251 1.649-.986-2.487a326.14 326.14 0 0 0-2.5 2.724c-.234.268-3.441 2.366-5.016 3.38-.037-.004-.15.096-.306.532-.194.544-.889.614-1.611-.196S.544 25.688.836 25.66c.233-.022.81.54 1.07.824a1.951 1.951 0 0 1-.195-.614c-.028-.294.236-.056.625.614.311.537.806.95 1.014 1.09l3.987-3.716c.764-1.15 2.473-3.61 3.195-4.246Z"
        />
        <path
          fill="#353F4B"
          d="m17.544 18.6-1.59-.663 1.425.958 1.29 1.492-1.125-1.788ZM16.664 13.856c-.104-.053-.43-.334-.578-.467.043.03.194.123.448.24.318.147.462 0 .563.254.08.203-.005.548-.058.695a2.648 2.648 0 0 0-.072-.4c-.043-.135-.173-.255-.303-.322Z"
        />
        <path
          fill="#3E4957"
          d="M20.476 32.925c-.78-1.758-2.386-4.38-3.092-5.472l-.738-.84-.933.546-.877.742 1.03 5.22 2.368 3.078h3.843c-.209-.359-.821-1.517-1.601-3.274Z"
        />
        <path
          fill="#353F4B"
          d="m20.932 36.2-4.565-9.097h.634c.966 1.53 3.01 4.886 3.462 6.07.452 1.182 1.264 2.51 1.614 3.027h-1.145Z"
        />
        <path
          fill="#16B054"
          d="M12.965 21.783c.232-1.142-.032-2.937-.194-3.692l.29-.154c.148.214.54.792.926 1.384.386.593 1.174 1.888 1.52 2.462 0 0 .096-1.12.11-1.972.014-.853.124-1.944.124-1.944l.221.07c.12.284.232.943.608 1.692.47.937 1.409 2.084 1.52 2.321.11.238-.07.672-.402 1.259-.265.47-.763 1.016-.98 1.23v.728c.074.214.31.82.663 1.524.354.705 1.52 3.081 2.058 4.181l-2.72-3.538.248 8.866h-6.231c-1.045-1.771-2.921-6.005-1.96-8.209.961-2.203 2.657-2.931 3.384-3.02l.304-.532-.304-.88c.175-.117.583-.635.815-1.776Z"
        />
        <path
          fill="#353F4B"
          d="M16.304 8.844c-.099-.896-.885-1.25-1.265-1.314.422-.07 1.367.014 2.159 1.065.792 1.052 1.109 2.565 1.113 2.975-.316-.392-1.22-1.51-1.76-1.466-.539.044-.88.18-1.003.276.316-.277.855-.639.756-1.536ZM10.374 19.406l2.374-1.26c-.45.291-1.51 1.02-2.165 1.607S8.992 21.52 8.57 22.065l1.804-2.659Z"
        />
        <path
          fill="#fff"
          d="m16.285 19.858-2.144-.14v.67c0 .195 0 .251.056.251h.79V36.2h.536l-.127-15.617c.202.005.64.011.776 0 .135-.01.132-.162.113-.237v-.488ZM14.133 19.258v-.404c.634.032 1.93.1 2.03.111.128.014.185.474.072.488-.09.011-1.44-.125-2.102-.195ZM16.238 18.715l-2.102-.181c-.038-.13-.1-.394-.043-.405.07-.014 1.976.321 2.074.363.079.033.08.162.07.223ZM16.238 18.268l-2.144-.377v-.25c0-.172.14-.533.705-.6.705-.084 1.129.237 1.326.474.159.19.141.581.113.753Z"
        />
      </g>
    </g>
    <path
      fill="#3E4957"
      d="m15.661 1.992-3.509-.02a.73.73 0 0 0-.734.727v.065a.73.73 0 0 0 .726.734l3.509.02a.73.73 0 0 0 .734-.727v-.065a.73.73 0 0 0-.726-.734Z"
    />
    <path
      fill="#fff"
      d="M26.553 21.184C24.416 12.668 24.82 7.764 23.3 7.605c-1.108-.116-2.312 2.388-2.782 3.732-1.073 3.075.19 4.405-.238 8.338-.364 3.344-1.275 3.611-1.826 6.83-.39 2.282-.477 5.605 1.032 9.936l-7.517-.04a14.242 14.242 0 0 0 1.8 1.643 14.499 14.499 0 0 0 4.606 2.356v4.686l14.53-.08a98.96 98.96 0 0 0-.955-5.362c-1.864-9.028-3.524-11.002-5.396-18.46Z"
    />
    <path
      fill="#3E4957"
      d="M18.29 45.17v-4.708a14.359 14.359 0 0 1-6.383-4.005l-.134-.143 7.593.041c-1.187-3.46-1.524-6.78-.996-9.866.257-1.5.59-2.36.913-3.189.37-.952.718-1.85.913-3.633.187-1.72.049-2.946-.073-4.028-.157-1.388-.293-2.587.315-4.328.475-1.365 1.688-3.913 2.87-3.79.928.099 1.174 1.743 1.62 4.73.332 2.21.784 5.239 1.707 8.914.804 3.201 1.579 5.42 2.328 7.565.986 2.825 2.006 5.745 3.07 10.897.367 1.78.689 3.585.956 5.367l.015.098-14.715.078Zm-6.125-8.684a14.194 14.194 0 0 0 6.236 3.832l.06.018v4.662l14.348-.078a99.918 99.918 0 0 0-.94-5.261c-1.062-5.142-2.08-8.057-3.064-10.876-.75-2.148-1.527-4.37-2.332-7.58-.925-3.682-1.379-6.715-1.71-8.93-.434-2.902-.673-4.5-1.47-4.584-.991-.105-2.178 2.199-2.693 3.676-.594 1.704-.461 2.883-.305 4.252.123 1.091.263 2.328.073 4.066-.197 1.804-.55 2.714-.924 3.676-.319.823-.65 1.674-.903 3.158-.529 3.09-.183 6.42 1.028 9.895l.04.114-7.444-.04Z"
    />
    <defs>
      <clipPath id="a">
        <rect
          width={17.55}
          height={34.2}
          x={5.117}
          y={1.07}
          fill="#fff"
          rx={3}
        />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M-4-.83h37v37H-4z" />
      </clipPath>
    </defs>
  </svg>
);
export default OnlineEvent;
