import React from "react";
import { Spinner } from "react-bootstrap";
import Wrapper from ".";
import StepPagination from "../stepPagination";

interface IEventLoadingWrapperProps {
  step: number;
  title: string;
  stepName: string;
}

export default function EventLoadingWrapper({
  step,
  title,
  stepName,
}: IEventLoadingWrapperProps) {
  return (
    <Wrapper>
      <h1 className="fw-bold fs-1 mb-4 mb-md-5">{title}</h1>
      <StepPagination step={step} />
      <div className="fw-bold fs-4 mb-3">{stepName}</div>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: 200 }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </Wrapper>
  );
}
