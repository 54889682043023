import React, { createRef, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useTranslation } from "react-i18next";
interface IImageCropModalProps {
  image: string;
  handleClose: () => void;
  handleSave: (image: string) => void;
}
export default function ImageCropModal({
  image,
  handleClose,
  handleSave,
}: IImageCropModalProps) {
  const { t } = useTranslation();
  const cropperRef = createRef<ReactCropperElement>();

  const [scale, setScale] = useState(0.5);
  const handleCrop = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      handleSave(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    }
  };

  useEffect(() => {
    if (cropperRef.current && scale !== 0) {
      const cropper = cropperRef.current.cropper;
      const containerData = cropper.getContainerData();
      const canvasData = cropper.getCanvasData();
      const newCanvasData = {
        ...canvasData,
        left: (containerData.width - canvasData.width) / 2,
        top: (containerData.height - canvasData.height) / 2,
      };
      cropper.setCanvasData(newCanvasData);
    }
    // eslint-disable-next-line
  }, [scale]);

  return (
    <Modal show={!!image.length} onHide={handleClose} centered>
      <Modal.Header closeButton className="border-0">
        <Modal.Title>{t("firstStep.uploadImage")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <Cropper
          ref={cropperRef}
          style={{ height: 350, width: "100%" }}
          src={image}
          viewMode={1}
          zoomTo={scale}
          background={false}
          responsive={false}
          autoCropArea={1}
          checkOrientation={false}
          aspectRatio={3 / 4}
          guides={true}
          className="mb-4"
        />
        <Row className="justify-content-center mb-3">
          <Col xs={8}>
            <Form.Range
              min="0"
              max="1"
              step="0.01"
              value={scale}
              onChange={(e) => setScale(Number(e.target.value))}
            />
          </Col>
        </Row>

        <Button
          variant="success"
          className="fw-bold py-3 px-5"
          onClick={handleCrop}
        >
          {t("firstStep.save")}
        </Button>
      </Modal.Body>
    </Modal>
  );
}
