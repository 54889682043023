import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { clearLocalStorageItems } from "../../helpers";
import useGetUrlLocale from "../../hooks/useGetUrlLocale";

interface ErrorItem {
  message: string;
  extensions?: { field?: string };
}

interface NetworkError {
  result?: {
    errors?: ErrorItem[];
  };
}

interface CustomError extends Error {
  networkError?: NetworkError;
}

interface ErrorProps {
  networkError?: NetworkError | CustomError;
}

const ApolloError: React.FC<ErrorProps> = ({ networkError }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const urlLocale = useGetUrlLocale();

  const errors: ErrorItem[] =
    networkError && "result" in networkError && networkError.result?.errors
      ? networkError.result.errors
      : [];

  useEffect(() => {
    if (
      errors.some(
        (item) =>
          item.message.includes("$poster") ||
          item.message.includes("The file is too large")
      )
    ) {
      setTimeout(() => {
        clearLocalStorageItems();
        id
          ? navigate(`${urlLocale}/create-event/${id}/edit`)
          : navigate(`${urlLocale}/create-event`);
      }, 2000);
    }
  }, [errors]);

  if (
    !networkError ||
    !("result" in networkError) ||
    !networkError.result?.errors
  ) {
    return null;
  }

  return (
    <div className="bg-danger bg-opacity-10 text-danger p-3 rounded">
      {errors.map((item, index) => (
        <div key={index} className={index === 0 ? "" : "mt-2"}>
          {item?.extensions?.field && <span>{item?.extensions?.field}: </span>}
          {JSON.stringify(item.message, null, 2)}
        </div>
      ))}
    </div>
  );
};

export default ApolloError;
