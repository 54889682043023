import React from "react";

const Online = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={67} height={45} fill="none">
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="M7.323.279H57.5c.872 0 1.58.708 1.58 1.58v33.134H5.744V1.859c0-.872.707-1.58 1.58-1.58Z"
    />
    <mask id="a" fill="#fff">
      <path d="M59.36 35.27 5.468 35.27V1.857C5.47.833 6.304 0 7.328 0l50.176.002c1.025 0 1.858.833 1.858 1.857l-.002 33.411Zm-53.636-.256 53.38.002.002-33.157c0-.884-.719-1.603-1.602-1.603L7.327.255c-.884 0-1.602.719-1.602 1.602l-.001 33.157Z" />
    </mask>
    <path
      fill="#fff"
      d="M59.36 35.27 5.468 35.27V1.857C5.47.833 6.304 0 7.328 0l50.176.002c1.025 0 1.858.833 1.858 1.857l-.002 33.411Zm-53.636-.256 53.38.002.002-33.157c0-.884-.719-1.603-1.602-1.603L7.327.255c-.884 0-1.602.719-1.602 1.602l-.001 33.157Z"
    />
    <path
      fill="#3E4957"
      d="M59.36 35.27v.3h.3v-.3h-.3ZM5.468 35.27h-.3v.3h.3v-.3Zm0-33.412h.3-.3ZM7.328 0v-.3.3Zm50.176.002v.3-.3Zm1.858 1.857h.3-.3ZM5.724 35.014h-.3v.3h.3v-.3Zm53.38.002v.3h.3v-.3h-.3Zm.002-33.157h.3-.3ZM57.504.256v.3-.3ZM7.327.255v-.3.3ZM5.725 1.857h.3-.3ZM59.36 34.97 5.468 34.97v.6l53.89.001v-.6Zm-53.591.299V1.857h-.6v33.412h.6Zm0-33.412C5.77.999 6.47.3 7.328.3v-.6C6.137-.3 5.17.667 5.17 1.857h.6ZM7.328.3l50.177.002v-.6L7.327-.3v.6Zm50.177.002c.858 0 1.556.698 1.556 1.557h.6c0-1.19-.967-2.157-2.156-2.157v.6Zm1.556 1.557V35.27h.6V1.86h-.6ZM5.724 35.314l53.38.002v-.6l-53.38-.002v.6Zm53.68-.298.002-33.157h-.6l-.001 33.157h.6Zm.002-33.157c0-1.05-.853-1.903-1.902-1.903v.6c.717 0 1.302.585 1.302 1.303h.6ZM57.504-.044 7.327-.045v.6l50.177.001v-.6ZM7.327-.045c-1.05 0-1.902.853-1.902 1.902h.6c0-.718.584-1.302 1.302-1.302v-.6ZM5.425 1.857l-.001 33.157h.6V1.857h-.6Z"
      mask="url(#a)"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="M3.999 38.27A3.322 3.322 0 0 1 .68 34.955l62.308.001a3.322 3.322 0 0 1-3.318 3.316L4 38.27Z"
    />
    <mask id="b" fill="#fff">
      <path d="M59.674 38.55 4 38.547a3.599 3.599 0 0 1-3.595-3.595v-.275l62.863.001v.276a3.599 3.599 0 0 1-3.595 3.594ZM.66 34.932v.02a3.344 3.344 0 0 0 3.34 3.34l55.673.002a3.344 3.344 0 0 0 3.34-3.34v-.02L.66 34.933Z" />
    </mask>
    <path
      fill="#fff"
      d="M59.674 38.55 4 38.547a3.599 3.599 0 0 1-3.595-3.595v-.275l62.863.001v.276a3.599 3.599 0 0 1-3.595 3.594ZM.66 34.932v.02a3.344 3.344 0 0 0 3.34 3.34l55.673.002a3.344 3.344 0 0 0 3.34-3.34v-.02L.66 34.933Z"
    />
    <path
      fill="#3E4957"
      d="M59.674 38.55v.3-.3ZM4 38.547v-.3.3Zm-3.595-3.87v-.3h-.3v.3h.3Zm62.863.001h.3v-.3h-.3v.3ZM.66 34.933v-.3h-.3v.3h.3Zm3.34 3.36v-.3.3Zm55.673.002v.3-.3Zm3.34-3.36h.3v-.3h-.3v.3Zm-3.34 3.314L4 38.248v.6l55.673.001v-.6ZM4 38.248a3.299 3.299 0 0 1-3.295-3.295h-.6a3.899 3.899 0 0 0 3.895 3.895v-.6ZM.706 34.953v-.275h-.6v.275h.6Zm-.3.025 62.863.001v-.6L.406 34.378v.6Zm62.563-.299v.276h.6v-.276h-.6Zm0 .276a3.3 3.3 0 0 1-3.295 3.294v.6a3.899 3.899 0 0 0 3.895-3.894h-.6ZM.36 34.933v.02h.6v-.02h-.6Zm0 .02a3.644 3.644 0 0 0 3.64 3.64v-.6a3.044 3.044 0 0 1-3.04-3.04h-.6Zm3.64 3.64 55.673.002v-.6L4 37.993v.6Zm55.673.002a3.644 3.644 0 0 0 3.64-3.64h-.6a3.044 3.044 0 0 1-3.04 3.04v.6Zm3.64-3.64v-.02h-.6v.02h.6Zm-.3-.32L.66 34.633v.6l62.353.002v-.6Z"
      mask="url(#b)"
    />
    <path
      fill="#3E4957"
      d="M23.945 35.861H40.88c0 .832-.674 1.507-1.506 1.507H25.452a1.507 1.507 0 0 1-1.507-1.507Z"
    />
    <path
      fill="#fff"
      d="M54.736 2.83H10.48a2.036 2.036 0 0 0-2.036 2.036v25.15c0 1.125.912 2.036 2.036 2.036h44.255a2.036 2.036 0 0 0 2.036-2.036V4.866a2.036 2.036 0 0 0-2.036-2.036Z"
    />
    <path
      fill="#3E4957"
      d="m54.697 32.14-44.17-.002c-1.196 0-2.168-.933-2.168-2.081V4.825c0-1.147.973-2.081 2.168-2.08h44.171c1.195 0 2.167.934 2.167 2.082v25.231c0 1.148-.973 2.081-2.168 2.081ZM10.527 2.915c-1.096 0-1.988.857-1.988 1.91v25.23c0 1.053.891 1.91 1.987 1.91l44.171.001c1.096 0 1.989-.856 1.989-1.909V4.827c0-1.053-.892-1.91-1.988-1.91l-44.17-.001Z"
    />
    <path
      fill="#16B054"
      d="m21.76 13.787 19.398-3.781a.969.969 0 0 1 1.137.765l1.952 10.016a.969.969 0 0 1-.766 1.136l-19.398 3.781a.969.969 0 0 1-1.137-.765l-1.952-10.016a.969.969 0 0 1 .766-1.136Z"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="m42.926 14.808-.435-2.232-21.006 4.095.435 2.232 21.006-4.095Z"
    />
    <path
      fill="#fff"
      d="m38.47 20.18 3.671-.717a.35.35 0 0 1 .134.687l-3.672.716a.35.35 0 1 1-.133-.687ZM32.714 18.607l8.224-1.603a.35.35 0 0 1 .134.687l-8.224 1.603a.35.35 0 1 1-.134-.687ZM24.555 20.197l6.742-1.314a.318.318 0 0 1 .373.252l.012.062a.318.318 0 0 1-.251.373l-6.742 1.314a.318.318 0 0 1-.373-.251l-.012-.063a.318.318 0 0 1 .251-.373ZM23.547 29.764a3.367 3.367 0 1 0 0-6.735 3.367 3.367 0 0 0 0 6.735Z"
    />
    <path
      fill="#3E4957"
      d="M23.545 29.93a3.537 3.537 0 0 1-3.29-4.812 3.537 3.537 0 0 1 4.572-2.015 3.537 3.537 0 0 1 2.017 4.572 3.54 3.54 0 0 1-3.299 2.256Zm.008-6.734a3.207 3.207 0 0 0-2.988 2.043 3.204 3.204 0 0 0 1.826 4.142 3.204 3.204 0 0 0 4.143-1.826 3.205 3.205 0 0 0-2.98-4.36Z"
    />
    <path
      fill="#3E4957"
      d="M23.545 29.246a2.84 2.84 0 0 1-1.028-.193 2.834 2.834 0 0 1-1.578-1.508 2.833 2.833 0 0 1-.049-2.182 2.855 2.855 0 0 1 3.69-1.627 2.855 2.855 0 0 1 1.627 3.69 2.857 2.857 0 0 1-2.662 1.82Zm.007-5.597a2.75 2.75 0 0 0-2.563 1.753 2.727 2.727 0 0 0 .047 2.1 2.748 2.748 0 0 0 5.071-.115 2.727 2.727 0 0 0-.047-2.1 2.728 2.728 0 0 0-1.519-1.452 2.734 2.734 0 0 0-.99-.186Z"
    />
    <path
      fill="#3E4957"
      d="M22.901 28.258c-.282-.11-.5-.245-.653-.407a1.045 1.045 0 0 1-.284-.535c-.035-.195-.021-.4.043-.613l.652.115a1.631 1.631 0 0 0-.061.401.677.677 0 0 0 .095.372c.068.114.188.204.362.271.178.07.326.085.445.048a.405.405 0 0 0 .258-.26.566.566 0 0 0 .001-.453 2.715 2.715 0 0 0-.264-.448l-.616-.854a1.206 1.206 0 0 1-.225-.479.908.908 0 0 1 .062-.5.76.76 0 0 1 .547-.494c.262-.065.571-.029.926.109.194.075.357.16.49.254.133.095.234.2.302.315.07.115.11.243.123.383.013.14 0 .292-.041.457l-.626-.11c.034-.123.052-.241.054-.355a.518.518 0 0 0-.097-.314c-.067-.096-.186-.177-.355-.243-.17-.066-.315-.084-.436-.054a.363.363 0 0 0-.257.24.49.49 0 0 0-.03.301c.023.091.077.196.161.313l.622.856c.14.19.248.396.325.615a.932.932 0 0 1-.015.667.776.776 0 0 1-.33.402c-.151.09-.328.134-.53.134s-.418-.044-.648-.134Z"
    />
    <path
      fill="#3E4957"
      d="m24.76 24.085-.541-.21-1.877 4.832.54.21 1.878-4.832Z"
    />
    <path
      fill="#fff"
      d="M30.148 27.49a2.578 2.578 0 1 0 0-5.156 2.578 2.578 0 0 0 0 5.156Z"
    />
    <path
      fill="#3E4957"
      d="M32.27 26.592a2.708 2.708 0 0 1-4.451-.31 2.708 2.708 0 0 1 .96-3.702 2.708 2.708 0 0 1 3.702.96 2.71 2.71 0 0 1-.212 3.052Zm-4.04-3.203a2.455 2.455 0 0 0-.193 2.765 2.453 2.453 0 0 0 3.355.87 2.453 2.453 0 0 0 .87-3.355 2.453 2.453 0 0 0-4.033-.28Z"
    />
    <path
      fill="#3E4957"
      d="M31.862 26.267a2.172 2.172 0 0 1-.604.526 2.169 2.169 0 0 1-1.655.231 2.185 2.185 0 0 1-.558-3.995 2.185 2.185 0 0 1 2.988.775c.47.799.371 1.777-.171 2.463Zm-3.358-2.661a2.105 2.105 0 0 0-.165 2.37c.285.485.741.829 1.284.97a2.104 2.104 0 0 0 1.055-4.068 2.087 2.087 0 0 0-1.592.222c-.23.135-.425.308-.582.506Z"
    />
    <path
      fill="#3E4957"
      d="M30.961 26.185c-.2.118-.385.184-.555.2a.805.805 0 0 1-.456-.085.986.986 0 0 1-.347-.316l.378-.337c.062.085.133.16.212.227.08.067.169.107.269.12.1.013.211-.016.334-.089.126-.073.206-.155.24-.244a.31.31 0 0 0-.034-.279.432.432 0 0 0-.271-.215 2.056 2.056 0 0 0-.395-.054l-.804-.036a.925.925 0 0 1-.395-.092.694.694 0 0 1-.27-.275.58.58 0 0 1-.038-.563c.086-.188.254-.356.505-.504a1.73 1.73 0 0 1 .386-.174.808.808 0 0 1 .332-.032c.102.014.198.05.289.108.09.059.175.14.254.242l-.363.324a1.101 1.101 0 0 0-.188-.201.395.395 0 0 0-.235-.09.575.575 0 0 0-.314.097c-.12.07-.2.15-.24.236a.278.278 0 0 0 .023.268.375.375 0 0 0 .167.161c.065.03.153.047.264.052l.809.033c.18.007.355.04.524.097.168.058.3.166.393.326a.596.596 0 0 1 .084.389.776.776 0 0 1-.17.381c-.096.122-.226.23-.389.326h.001Z"
    />
    <path
      fill="#3E4957"
      d="m29.344 23.087-.383.226 2.01 3.421.383-.225-2.01-3.422ZM56.78 7.254H8.452v.172H56.78v-.172ZM13.202 6.366a1.21 1.21 0 1 0 0-2.42 1.21 1.21 0 0 0 0 2.42ZM18.359 6.366a1.21 1.21 0 1 0 0-2.42 1.21 1.21 0 0 0 0 2.42ZM23.507 6.366a1.21 1.21 0 1 0 0-2.42 1.21 1.21 0 0 0 0 2.42Z"
    />
    <path
      fill="#3E4957"
      d="M65.31 10.502 52.304 6.913a1.751 1.751 0 0 0-2.154 1.222l-8.46 30.654a1.751 1.751 0 0 0 1.222 2.154l13.004 3.59a1.751 1.751 0 0 0 2.154-1.223l8.46-30.653a1.751 1.751 0 0 0-1.222-2.155Z"
    />
    <path
      fill="#fff"
      d="m64.474 11.642-12.03-3.32a1.62 1.62 0 0 0-1.992 1.13L42.625 37.81a1.62 1.62 0 0 0 1.13 1.993l12.03 3.32a1.62 1.62 0 0 0 1.993-1.13l7.827-28.357a1.62 1.62 0 0 0-1.13-1.993Z"
    />
    <path
      fill="#3E4957"
      d="m60.941 9.569-4.398-1.214a.953.953 0 0 0-1.172.665l-.056.206a.953.953 0 0 0 .665 1.172l4.397 1.214a.953.953 0 0 0 1.172-.665l.057-.206a.953.953 0 0 0-.665-1.172Z"
    />
    <path
      fill="#16B054"
      d="m61.645 13.89-1.228-.336a3.1 3.1 0 0 1-5.545-1.515l-1.228-.335a1.833 1.833 0 0 0-2.25 1.285l-6.321 23.14a1.833 1.833 0 0 0 1.285 2.251l1.263.345a3.115 3.115 0 0 1 5.476 1.495l1.262.345a1.833 1.833 0 0 0 2.251-1.285l6.32-23.14a1.832 1.832 0 0 0-1.285-2.251Zm-9.903 2.777a.664.664 0 1 1 .35-1.282.664.664 0 0 1-.35 1.282Zm1.796.49a.665.665 0 1 1 .35-1.282.665.665 0 0 1-.35 1.282Zm1.795.49a.665.665 0 1 1 .35-1.281.665.665 0 0 1-.35 1.282Zm1.796.491a.664.664 0 1 1 .35-1.281.664.664 0 0 1-.35 1.281Zm1.795.49a.665.665 0 1 1 .35-1.282.665.665 0 0 1-.35 1.282Zm1.796.49a.664.664 0 1 1 .35-1.281.664.664 0 0 1-.35 1.282Z"
    />
    <path
      fill="#fff"
      d="m51.469 28.048-1.2-.339a.713.713 0 0 1-.492-.879l.338-1.2a.713.713 0 0 1 .88-.492l1.2.339c.377.106.598.5.491.879l-.338 1.2a.713.713 0 0 1-.88.492Zm-.63-2.363a.145.145 0 0 0-.177.1l-.339 1.2a.145.145 0 0 0 .1.178l1.2.338a.145.145 0 0 0 .178-.1l.338-1.2a.145.145 0 0 0-.1-.178l-1.2-.338ZM50.152 23.788a.713.713 0 0 0-.879.493l-.231.818a.284.284 0 0 0 .546.154l.154-.545a.429.429 0 0 1 .53-.296l.567.16a.284.284 0 0 0 .154-.546l-.84-.237ZM55.83 35.549a.713.713 0 0 0 .878-.492l.231-.819a.284.284 0 0 0-.546-.154l-.154.545a.429.429 0 0 1-.529.296l-.568-.16a.284.284 0 1 0-.154.546l.841.237ZM58.969 27.004a.713.713 0 0 0-.493-.88l-.818-.23a.284.284 0 0 0-.154.546l.545.154c.228.064.36.3.296.529l-.16.567a.284.284 0 0 0 .546.155l.237-.841ZM47.011 32.335a.713.713 0 0 0 .492.88l.819.23a.284.284 0 1 0 .154-.546l-.545-.154a.429.429 0 0 1-.296-.529l.16-.568a.284.284 0 0 0-.546-.154l-.238.841Z"
    />
    <path
      fill="#fff"
      d="M51.23 26.903a.301.301 0 1 0 0-.602.301.301 0 0 0 0 .602ZM50.055 32.798l-1.2-.339a.713.713 0 0 1-.492-.879l.338-1.2a.713.713 0 0 1 .88-.492l1.2.339c.377.106.598.5.491.879l-.338 1.2a.713.713 0 0 1-.88.491Zm-.63-2.363a.145.145 0 0 0-.177.1l-.339 1.2a.145.145 0 0 0 .1.178l1.2.338a.145.145 0 0 0 .178-.1l.338-1.2a.145.145 0 0 0-.1-.178l-1.2-.338Z"
    />
    <path
      fill="#fff"
      d="M49.809 31.655a.301.301 0 1 0 0-.602.301.301 0 0 0 0 .602ZM56.187 29.378l-1.2-.339a.713.713 0 0 1-.491-.879l.338-1.2a.713.713 0 0 1 .88-.492l1.199.339c.378.106.599.5.492.879l-.339 1.2a.713.713 0 0 1-.879.492Zm-.628-2.363a.145.145 0 0 0-.179.1l-.338 1.2a.145.145 0 0 0 .1.178l1.2.338a.145.145 0 0 0 .178-.1l.338-1.2a.145.145 0 0 0-.1-.178l-1.2-.338Z"
    />
    <path
      fill="#fff"
      d="M55.942 28.235a.301.301 0 1 0 0-.602.301.301 0 0 0 0 .602ZM56.356 30.563a.301.301 0 1 0 0-.602.301.301 0 0 0 0 .602ZM53.801 32.766a.301.301 0 1 0 0-.602.301.301 0 0 0 0 .602ZM54.463 26.416l-.458 1.623a.3.3 0 1 1-.58-.163l.458-1.623a.301.301 0 1 1 .58.163ZM50.836 29.05l1.623.457a.3.3 0 1 1-.163.58l-1.623-.457a.3.3 0 1 1 .163-.58ZM53.503 27.585l-.35 1.239a.301.301 0 0 1-.58-.164l.269-.948a.3.3 0 0 1 .371-.208l.29.081Z"
    />
    <path
      fill="#fff"
      d="m54.092 27.75-.082.29a.3.3 0 0 1-.371.207l-.584-.164a.3.3 0 1 1 .164-.58l.873.247ZM54.373 26.637l-.081.29a.3.3 0 0 1-.372.207l-.584-.164a.3.3 0 1 1 .164-.58l.874.247ZM51.41 29.815l-.29-.08a.301.301 0 0 1-.21-.37l.16-.585a.301.301 0 0 1 .58.16l-.24.875ZM50.472 28.635a.3.3 0 0 1-.37.21l-.295-.08-.58-.16.011-.041.068-.249a.301.301 0 0 1 .37-.21l.048.013.537.147c.16.044.255.21.21.37Z"
    />
    <path
      fill="#fff"
      d="m49.891 28.474-.083.289-.085.294a.301.301 0 0 1-.578-.167l.094-.328.085-.295a.301.301 0 0 1 .353-.165l.048.014c.135.06.208.212.166.358ZM52.52 31.327a.32.32 0 0 1 .13-.186.252.252 0 0 1 .204-.038l.262.069.514.134-.01.042-.066.25a.37.37 0 0 1-.016.046c-.057.133-.19.21-.318.177l-.043-.01-.477-.125c-.142-.038-.223-.198-.181-.359Z"
    />
    <path
      fill="#fff"
      d="m53.018 31.292.08-.29.082-.295a.301.301 0 0 1 .58.16l-.09.33-.082.296a.301.301 0 0 1-.35.168l-.05-.013a.3.3 0 0 1-.17-.356ZM51.526 32.297l.08-.29.082-.294a.301.301 0 0 1 .58.16l-.09.33-.082.296a.301.301 0 0 1-.351.168l-.049-.013a.3.3 0 0 1-.17-.356Z"
    />
    <path
      fill="#fff"
      d="m52.313 32.83-.29-.08-.294-.08a.3.3 0 1 1 .16-.58l.329.09.296.082c.132.06.206.207.168.352l-.013.048a.301.301 0 0 1-.356.17v-.001Z"
    />
    <path
      fill="#fff"
      d="m52.687 32.62-.083.289-.084.294a.301.301 0 0 1-.579-.165l.094-.328.084-.296a.301.301 0 0 1 .352-.165l.049.013a.3.3 0 0 1 .166.357Z"
    />
    <path
      fill="#fff"
      d="m52.731 33.577-.289-.082-.294-.084a.3.3 0 1 1 .165-.58l.328.094.296.085a.3.3 0 0 1 .165.352l-.013.048a.3.3 0 0 1-.358.167ZM54.427 34.032l-.29-.082-.294-.084a.301.301 0 0 1 .165-.579l.328.093.296.085c.132.06.204.208.165.352l-.013.048a.3.3 0 0 1-.357.167Z"
    />
    <path
      fill="#fff"
      d="m55.231 34.259-.289-.083-.294-.083a.3.3 0 0 1 .165-.58l.328.094.296.084a.3.3 0 0 1 .165.352l-.013.049a.3.3 0 0 1-.358.166Z"
    />
    <path
      fill="#fff"
      d="m54.872 34.157-.29-.082-.293-.084a.301.301 0 0 1 .165-.579l.328.093.295.085c.132.06.205.208.166.352l-.014.048a.3.3 0 0 1-.357.167Z"
    />
    <path
      fill="#fff"
      d="m55.255 33.321-.081.29-.083.294a.301.301 0 0 1-.58-.163l.092-.328.084-.296a.301.301 0 0 1 .352-.166l.048.013a.3.3 0 0 1 .168.356ZM55.167 29.953l-.044.154a.432.432 0 0 1-.368.049l-.252-.07-.129-.037-.387-.109c-.21-.059-.344-.237-.298-.397a.27.27 0 0 1 .17-.173.463.463 0 0 1 .293-.009l.432.122.33.092.059.017c.048.02.09.045.126.076a.3.3 0 0 1 .068.285Z"
    />
    <path
      fill="#fff"
      d="m55.007 30.535-.044.154-.039.135-.083.294a.3.3 0 1 1-.58-.165l.082-.286.012-.042.085-.295a.302.302 0 0 1 .352-.165l.048.013a.3.3 0 0 1 .167.357Z"
    />
    <path
      fill="#fff"
      d="m55.17 29.953-.043.154-.039.135-.083.294a.3.3 0 0 1-.58-.165l.082-.286.012-.042.085-.295a.302.302 0 0 1 .352-.165l.048.013a.301.301 0 0 1 .167.357Z"
    />
    <path
      fill="#fff"
      d="m54.826 30.15.153.044.136.038.294.083a.3.3 0 1 1-.164.58l-.287-.081-.041-.012-.296-.084a.3.3 0 0 1-.165-.352l.013-.049a.3.3 0 0 1 .357-.167ZM53.74 29.242l.153.043.136.039.294.083a.3.3 0 1 1-.164.58l-.286-.082-.042-.011-.296-.084a.3.3 0 0 1-.165-.353l.013-.048a.3.3 0 0 1 .357-.167ZM55.076 31.72l.153.044.136.038.294.084a.3.3 0 1 1-.164.579l-.287-.081-.041-.012-.296-.084a.3.3 0 0 1-.165-.352l.013-.048a.3.3 0 0 1 .357-.167Z"
    />
    <path
      fill="#fff"
      d="m55.294 31.785.154.043.136.039.294.083a.3.3 0 1 1-.164.58l-.287-.082-.042-.011-.295-.084a.3.3 0 0 1-.166-.353l.013-.048a.3.3 0 0 1 .358-.167Z"
    />
    <path
      fill="#fff"
      d="m55.574 31.932.043-.154.039-.136.083-.294a.3.3 0 1 1 .58.164l-.082.287-.011.041-.084.296a.301.301 0 0 1-.352.166l-.048-.013a.3.3 0 0 1-.168-.357Z"
    />
    <path
      fill="#fff"
      d="m55.511 32.154.044-.153.038-.136.083-.294a.3.3 0 1 1 .58.163l-.081.287-.012.042-.083.295a.301.301 0 0 1-.352.167l-.049-.013a.3.3 0 0 1-.168-.357ZM52.319 29.465l.29.081a.3.3 0 0 1 .208.371l-.163.584a.301.301 0 0 1-.58-.162l.245-.874ZM50.431 19.553 52.07 20l-.11.406-1.639-.448.111-.405Zm.559.349.42.115-.56 2.051-.42-.115.56-2.051ZM52.108 22.412l-.436-.12.61-2.233.436.119-.61 2.234ZM52.92 22.552a.675.675 0 0 1-.2-.36c-.03-.142-.02-.3.028-.477l.168-.613a1.02 1.02 0 0 1 .218-.425.679.679 0 0 1 .354-.21.935.935 0 0 1 .464.021.767.767 0 0 1 .554.532c.04.135.052.288.035.459l-.436-.12a.592.592 0 0 0-.024-.21.378.378 0 0 0-.092-.153.335.335 0 0 0-.152-.086.406.406 0 0 0-.215-.007.327.327 0 0 0-.165.107.539.539 0 0 0-.105.211l-.168.614a.535.535 0 0 0-.017.235c.013.069.042.127.089.175.046.047.109.082.188.103a.347.347 0 0 0 .334-.08.57.57 0 0 0 .125-.17l.437.119a1.2 1.2 0 0 1-.266.375.77.77 0 0 1-.345.189.796.796 0 0 1-.4-.012.945.945 0 0 1-.41-.217ZM55.095 20.826l.42.115-.61 2.234-.42-.115.61-2.234Zm-.119 1.43 1.268-1.116.53.145-1.893 1.534.095-.562Zm.443-.156.425-.13.414 1.575-.5-.137-.34-1.308ZM57.15 21.387l.42.115-.61 2.234-.42-.115.61-2.234Zm.151.041 1.345.368-.11.405-1.345-.367.11-.406Zm-.251.922 1.175.321-.11.406-1.176-.321.11-.406Zm-.248.907 1.344.367-.11.405-1.345-.367.111-.405ZM58.783 21.834l1.638.447-.111.406-1.638-.447.11-.406Zm.558.349.421.115-.56 2.051-.421-.115.56-2.051Z"
    />
  </svg>
);
export default Online;
