import React from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useFormikContext } from "formik";
import { IEntity, SelectOption } from "../../../types";
import { useTranslation } from "react-i18next";
import SelectInput from "../../../components/inputs/select";
import InputText from "../../../components/inputs/inputText";
import InputPhone from "../../../components/inputs/inputPhone";
import { useGetCountriesQuery } from "../../../qraphql/event.hooks";
import InputNumber from "../../../components/inputs/inputNumber";
import { QuestionCircle } from "react-bootstrap-icons";

export default function PaymentDirect() {
  const { t } = useTranslation();
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<IEntity>();
  const { data: countriesData } = useGetCountriesQuery();
  const optionsCountries =
    countriesData?.countries
      .map((item) => ({
        value: item.id.toString(),
        label: item.country,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const handleFeeChange = () => {
    setFieldValue("bankDirect.isIncludeFee", !values.bankDirect.isIncludeFee);
  };

  return (
    <>
      <div className="fw-bold fs-4 mb-3">{t("thirdStep.bankAccInfo")}</div>
      <Row className="g-3 gy-2 pb-3">
        <Col lg={6}>
          <InputText
            placeholder={t("secondStep.email")}
            name={`bankDirect.email`}
          />
        </Col>
        <Col lg={6}>
          <InputPhone
            country={values.countryCode}
            name={`bankDirect.phone`}
            value={values.bankDirect.phone}
            onChange={(phone) => setFieldValue(`bankDirect.phone`, phone)}
            onBlur={() => setFieldTouched(`bankDirect.phone`, true)}
          />
        </Col>
        <Col lg={6}>
          <InputText
            placeholder={t("firstStep.address")}
            name={`bankDirect.address`}
          />
        </Col>
        <Col lg={6}>
          <InputText
            placeholder={t("firstStep.city")}
            name={`bankDirect.city`}
          />
        </Col>
        <Col lg={6}>
          <div className="hide-arrows">
            <InputNumber
              placeholder={t("thirdStep.postalCode")}
              name={`bankDirect.postalCode`}
            />
          </div>
        </Col>
        <Col lg={6}>
          <SelectInput
            options={optionsCountries}
            label={t("thirdStep.countryResidence")}
            name={`bankDirect.countryResidence`}
            placeholder={t("firstStep.selectCountry")}
            onBlur={() => setFieldTouched("bankDirect.countryResidence", true)}
            onChange={(selectedOption: SelectOption | null) => {
              setFieldValue(
                "bankDirect.countryResidence",
                selectedOption ? selectedOption.value : ""
              );
            }}
            value={optionsCountries.find(
              (option) => option.value === values.bankDirect.countryResidence
            )}
          />
        </Col>
        <Col lg={6}>
          <SelectInput
            options={optionsCountries}
            label={t("thirdStep.bankCountry")}
            name={`bankDirect.countryAccount`}
            placeholder={t("firstStep.selectCountry")}
            onBlur={() => setFieldTouched("bankDirect.countryAccount", true)}
            onChange={(selectedOption: SelectOption | null) => {
              setFieldValue(
                "bankDirect.countryAccount",
                selectedOption ? selectedOption.value : ""
              );
            }}
            value={optionsCountries.find(
              (option) => option.value === values.bankDirect.countryAccount
            )}
          />
        </Col>
        <Col lg={6}>
          <InputText
            placeholder={t("thirdStep.bankName")}
            name={`bankDirect.bankName`}
          />
        </Col>
        <Col lg={6}>
          <InputText
            placeholder={t("thirdStep.holderName")}
            name={`bankDirect.holderName`}
          />
        </Col>
        <Col lg={6}>
          <InputText
            placeholder={t("thirdStep.iban")}
            name={`bankDirect.iban`}
          />
        </Col>
      </Row>
      <div className="fs-5 fw-bold mt-4 mb-3">
        {t("thirdStep.takesService")} <FeeTooltip />
      </div>
      <Form.Check
        type="radio"
        id="includeService"
        label={t("thirdStep.includeService")}
        name="bankDirect.isIncludeFee"
        checked={values.bankDirect.isIncludeFee === true}
        onChange={handleFeeChange}
        className="mb-2"
      />
      <Form.Check
        type="radio"
        id="chargeService"
        label={t("thirdStep.chargeService")}
        name="bankDirect.isIncludeFee"
        checked={values.bankDirect.isIncludeFee === false}
        onChange={handleFeeChange}
      />
    </>
  );
}

const FeeTooltip = () => {
  const { t } = useTranslation();
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id="tooltipFee" className="bg-dark">
          <span>
            {t(
              "thirdStep.ME-Ticket service commission of 8% (includes SWIFT transfer 1.8% and currency conversion 0,5%)."
            )}
          </span>
        </Tooltip>
      }
    >
      <QuestionCircle className="ms-1 mb-1" size={16} />
    </OverlayTrigger>
  );
};
