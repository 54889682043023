import React from "react";
const Returnable = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} fill="none">
    <path
      fill="#16B054"
      d="m42.027 3.809-2.11-.531c-1.245 1.487-3.431 2.207-5.586 1.664C32.177 4.4 30.675 2.751 30.4.88L28.292.35c-1.677-.423-3.386.447-3.818 1.942L14.229 37.73c-.432 1.495.577 3.05 2.253 3.472l2.167.546c1.27-1.392 3.392-2.049 5.483-1.522 2.09.527 3.57 2.09 3.917 3.89l2.168.545c1.676.423 3.385-.447 3.817-1.943L44.28 7.281c.432-1.495-.576-3.05-2.252-3.471v-.001ZM25.16 7.942c-.608-.153-.973-.716-.817-1.258.157-.542.776-.858 1.384-.705.608.154.974.717.817 1.259-.157.542-.777.857-1.384.704Zm3.082.776c-.608-.153-.973-.716-.817-1.258.157-.542.777-.857 1.384-.705.608.153.974.717.817 1.259-.157.542-.776.858-1.384.704Zm3.082.777c-.608-.153-.973-.717-.816-1.259.157-.542.776-.857 1.384-.704.608.153.973.716.816 1.259-.157.541-.776.857-1.384.704Zm3.082.776c-.607-.153-.972-.716-.816-1.258.157-.543.776-.858 1.384-.705.607.153.973.716.816 1.259-.157.542-.776.857-1.384.704Zm3.083.777c-.608-.153-.973-.717-.817-1.259.157-.542.776-.858 1.384-.704.608.153.974.716.817 1.258-.157.542-.776.858-1.384.705Zm3.082.776c-.608-.153-.973-.716-.816-1.258.156-.543.776-.858 1.383-.705.608.153.974.716.817 1.259-.157.542-.776.857-1.384.704Z"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.3}
      d="m33.154 1.897.13.008c1.657.092 2.86 1.367 2.73 2.818L32.68 41.459c-.133 1.456-1.562 2.589-3.222 2.496l-2.137-.12c-.731-1.714-2.511-2.959-4.68-3.08-2.17-.121-4.154.916-5.182 2.53l-2.143-.12c-1.657-.093-2.86-1.368-2.73-2.818L15.92 3.61c.132-1.456 1.563-2.588 3.222-2.496l2.072.116c.676 1.799 2.496 3.123 4.73 3.248 2.236.124 4.273-.986 5.262-2.69l1.948.109ZM17.51 9.163c.692.039 1.323-.434 1.383-1.09.06-.662-.487-1.205-1.182-1.244-.692-.039-1.323.434-1.383 1.09-.06.662.487 1.205 1.182 1.244Zm3.173.177c.693.04 1.324-.434 1.383-1.09.06-.662-.487-1.206-1.181-1.244-.692-.039-1.323.434-1.383 1.09-.06.662.487 1.205 1.181 1.244Zm3.174.178c.692.038 1.323-.435 1.383-1.09.06-.662-.487-1.206-1.181-1.245-.693-.038-1.324.435-1.383 1.09-.06.662.486 1.206 1.181 1.245Zm1.992-1.067c-.06.661.487 1.205 1.181 1.244l.009-.15-.009.15c.693.039 1.324-.435 1.383-1.09.06-.662-.487-1.206-1.181-1.244-.692-.04-1.323.434-1.383 1.09Zm3.174.177c-.06.662.487 1.206 1.181 1.245.693.038 1.323-.435 1.383-1.09.06-.663-.487-1.206-1.181-1.245-.693-.039-1.324.434-1.383 1.09Zm4.355 1.422c.692.038 1.323-.435 1.383-1.09.06-.662-.487-1.206-1.182-1.245-.692-.038-1.323.435-1.383 1.09v.001c-.06.661.487 1.205 1.182 1.244Z"
    />
    <path
      fill="#16B054"
      stroke="#3E4957"
      strokeWidth={0.5}
      d="M15.88 15.586c5.006 0 9.065 3.95 9.065 8.824s-4.059 9.09-9.066 9.09c-5.007 0-10.154-4.216-10.154-9.09 0-1.315.439-2.97.967-4.089.21-.442.484-.94 0-.94-.483 0-.493.168-.703.611A10.318 10.318 0 0 0 5 24.41C5 30.26 9.87 35 15.88 35 21.886 35 27 30.259 27 24.41c0-6.442-5.113-10.59-11.12-10.59v-2.587c0-.374-.358-.24-.653 0l-4.37 3.131c-.217.177-.217.373 0 .55l4.055 3.054c.295.24.967.58.967.205v-2.587Z"
    />
  </svg>
);
export default Returnable;
