import React, { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import First from "./steps/first";
import { IEntity } from "./types";
import Second from "./steps/second";
import Third from "./steps/third";
import Fourth from "./steps/fourth";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apollo/apolloClient";
import { base64ToFile, imageFileToBase64 } from "./helpers";
import { DEFAULT_ENTITY } from "./constants";

const pathArray = [
  "/create-event",
  "/tickets-info",
  "/payment-info",
  "/check-publish",
];

export default function App() {
  const [submittedStep, setSubmittedStep] = useState(0);
  const [entity, setEntity] = useState<IEntity>(DEFAULT_ENTITY);

  const handleUpdateEntity = async (values: IEntity, currentStep: number) => {
    setEntity(values);
    setSubmittedStep(currentStep);

    localStorage.setItem(
      "entity",
      JSON.stringify({
        ...entity,
        ...values,
        galery: null,
        banner: null,
        poster: null,
      })
    );
    localStorage.setItem(
      "options",
      JSON.stringify({ submittedStep: currentStep })
    );
    if (values.poster !== null) {
      const posterBase64 = await imageFileToBase64(values.poster);
      localStorage.setItem("poster", posterBase64);
    }
  };

  useEffect(() => {
    const entityData = localStorage.getItem("entity");
    const posterData = localStorage.getItem("poster");
    const optionsData = localStorage.getItem("options");

    if (entityData) {
      const parsedEntityData = JSON.parse(entityData) as IEntity;
      setEntity((prev) => ({ ...prev, ...parsedEntityData }));
    }
    if (posterData) {
      const posterFile = base64ToFile(posterData, "poster");
      setEntity((prev) => ({ ...prev, poster: posterFile }));
    }
    if (optionsData) {
      const { submittedStep } = JSON.parse(optionsData) as {
        submittedStep: number;
      };
      setSubmittedStep(submittedStep);
    }
  }, []);

  useEffect(() => {
    const checkStep = () => {
      const currentPath = window.location.pathname;
      if (!pathArray.includes(currentPath)) return;

      const options = localStorage.getItem("options");

      if (options) {
        const { submittedStep } = JSON.parse(options);
        const newPath = pathArray[submittedStep];
        if (currentPath !== newPath) {
          window.location.href = newPath;
        }
      } else {
        const defaultPath = pathArray[0];
        if (currentPath !== defaultPath) {
          window.location.href = defaultPath;
        }
      }
    };

    setTimeout(() => {
      checkStep();
    }, 0);
  }, []);

  const FirstStep = (
    <First
      entity={entity}
      handleUpdateEntity={handleUpdateEntity}
      step={submittedStep + 1}
    />
  );

  const SecondStep = (
    <Second
      entity={entity}
      handleUpdateEntity={handleUpdateEntity}
      step={submittedStep + 1}
    />
  );

  const ThirdStep = (
    <Third
      entity={entity}
      handleUpdateEntity={handleUpdateEntity}
      step={submittedStep + 1}
    />
  );

  const FourthStep = (
    <Fourth
      entity={entity}
      handleUpdateEntity={handleUpdateEntity}
      step={submittedStep + 1}
    />
  );

  const router = createBrowserRouter([
    // Routes without locale
    {
      path: "/create-event",
      element: FirstStep,
      children: [
        {
          path: ":id/edit",
          element: FirstStep,
        },
      ],
    },
    {
      path: "/tickets-info",
      element: SecondStep,
      children: [
        {
          path: ":id/edit",
          element: SecondStep,
        },
      ],
    },
    {
      path: "/payment-info",
      children: [
        {
          path: ":id/edit",
          element: ThirdStep,
        },
        {
          path: "connect/:eventId",
          element: ThirdStep,
        },
      ],
    },
    {
      path: "/check-publish",
      element: FourthStep,
    },
    // Routes with locale
    {
      path: "/:locale/create-event",
      element: FirstStep,
      children: [
        {
          path: ":id/edit",
          element: FirstStep,
        },
      ],
    },
    {
      path: "/:locale/tickets-info",
      element: SecondStep,
      children: [
        {
          path: ":id/edit",
          element: SecondStep,
        },
      ],
    },
    {
      path: "/:locale/payment-info",
      children: [
        {
          path: ":id/edit",
          element: ThirdStep,
        },
        {
          path: "connect/:eventId",
          element: ThirdStep,
        },
      ],
    },
    {
      path: "/:locale/check-publish",
      element: FourthStep,
    },
  ]);

  return (
    <ApolloProvider client={apolloClient}>
      <RouterProvider router={router} />
    </ApolloProvider>
  );
}
