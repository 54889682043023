import React, { ReactNode, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Menu from "../menu";

interface IWrapperProps {
  children: ReactNode;
}

export default function Wrapper({ children }: IWrapperProps) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Container fluid className="gx-lg-0">
      <Row className="d-lg-flex m-md-0 gx-md-0 wrapper">
        <Menu />
        <div className="col-12 col-lg-9 col-xl-10 bg-white mt-5 mt-lg-0">
          <div className="col-12 col-lg-10 col-xl-10 offset-lg-1 width-wrapper mt-5 text-dark">
            {children}
          </div>
        </div>
      </Row>
    </Container>
  );
}
