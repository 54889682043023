import {
  EndRecurrenceEnum,
  IEntity,
  ITicket,
  RepeatPeriodEnum,
  TicketTypeEnum,
} from "../types";

export const tickets: ITicket[] = [
  {
    typeId: null,
    active: false,
    ticketType: TicketTypeEnum.Regular,
    quantity: 1,
    limitQuantity: 1,
    currency: "USD",
    description: "",
    price: null,
    id: null,
  },
  {
    typeId: null,
    active: false,
    ticketType: TicketTypeEnum.Free,
    quantity: 1,
    limitQuantity: 1,
    description: "",
    currency: "USD",
    id: null,
  },
  {
    typeId: null,
    active: false,
    ticketType: TicketTypeEnum.Vip,
    quantity: 1,
    limitQuantity: 1,
    currency: "USD",
    description: "",
    price: null,
    id: null,
  },
  {
    typeId: null,
    active: false,
    ticketType: TicketTypeEnum.FanZone,
    quantity: 1,
    limitQuantity: 1,
    currency: "USD",
    description: "",
    price: null,
    id: null,
  },
  {
    typeId: null,
    active: false,
    ticketType: TicketTypeEnum.Balcony,
    quantity: 1,
    limitQuantity: 1,
    currency: "USD",
    description: "",
    price: null,
    id: null,
  },
  {
    typeId: null,
    active: false,
    ticketType: TicketTypeEnum.Child,
    quantity: 1,
    limitQuantity: 1,
    currency: "USD",
    description: "",
    price: null,
    id: null,
  },
  {
    typeId: null,
    active: false,
    ticketType: TicketTypeEnum.Student,
    quantity: 1,
    limitQuantity: 1,
    currency: "USD",
    description: "",
    price: null,
    id: null,
  },
  {
    typeId: null,
    active: false,
    ticketType: TicketTypeEnum.Senior,
    quantity: 1,
    limitQuantity: 1,
    currency: "USD",
    description: "",
    price: null,
    id: null,
  },
  {
    typeId: null,
    active: false,
    ticketType: TicketTypeEnum.MerchBundle,
    quantity: 1,
    limitQuantity: 1,
    currency: "USD",
    description: "",
    price: null,
    id: null,
  },
  {
    typeId: null,
    active: false,
    ticketType: TicketTypeEnum.SingleDayPass,
    quantity: 1,
    limitQuantity: 1,
    currency: "USD",
    description: "",
    price: null,
    id: null,
  },
  {
    typeId: null,
    active: false,
    ticketType: TicketTypeEnum.WeekendPass,
    quantity: 1,
    limitQuantity: 1,
    currency: "USD",
    description: "",
    price: null,
    id: null,
  },
  {
    typeId: null,
    active: false,
    ticketType: TicketTypeEnum.EarlyBird,
    quantity: 1,
    limitQuantity: 1,
    currency: "USD",
    description: "",
    price: null,
    date: null,
    id: null,
  },
  {
    typeId: null,
    active: false,
    ticketType: TicketTypeEnum.LastMinute,
    quantity: 1,
    limitQuantity: 1,
    currency: "USD",
    description: "",
    price: null,
    date: null,
    id: null,
  },
];

export const DEFAULT_ENTITY: IEntity = {
  id: null,
  //step1
  title: "",
  categories: [],
  description: "",
  endDate: null,
  startDate: null,
  isOnline: false,
  isRecurring: false,
  repeats: RepeatPeriodEnum.Daily,
  recurrence: {
    repeatsEvery: 1,
    repeatsType: RepeatPeriodEnum.Daily,
    weekDays: [false, false, false, false, false, false, false],
    endRecurrence: EndRecurrenceEnum.NeverStop,
    onDate: null,
    endAfterOccurences: 1,
  },
  country: "",
  countryCode: "us",
  city: "",
  address: "",
  place: "",
  placeName: "",
  eventInvitationLink: "",
  guidelinesPlatformUse: "",
  poster: null,
  banner: null,
  galery: null,
  performers: [],
  newPerformers: [],
  //step2
  isReturnable: true,
  returnDescription: "",
  contacts: [
    {
      email: "",
      phone: "",
    },
  ],
  tickets,
  //step3
  isPayOnline: true,
  ticketAddresses: [""],
  merchantContacts: [
    {
      email: "",
      phone: "",
    },
  ],
  bankConnect: {
    currency: "",
    country: "",
    holderName: "",
    iban: "",
  },
  bankDirect: {
    email: "",
    phone: "",
    address: "",
    city: "",
    postalCode: null,
    countryResidence: "",
    countryAccount: "",
    bankName: "",
    holderName: "",
    iban: "",
    isIncludeFee: true,
  },
  stripe: {
    country: "",
    email: "",
  },
};
