import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import InputText from "../../../components/inputs/inputText";
import InputPhone from "../../../components/inputs/inputPhone";
import { FieldArray, useFormikContext } from "formik";
import { IEntity } from "../../../types";
import { Trash } from "react-bootstrap-icons";

export default function ReturnContacts() {
  const { t } = useTranslation();
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<IEntity>();

  useEffect(() => {
    // if (
    //   values.contacts.length > 1 &&
    //   values.contacts.some((item) => !item.email)
    // ) {
    // }
    setFieldTouched(`contacts[${values.contacts.length - 1}].email`, true);
    setFieldTouched(`contacts[${values.contacts.length - 1}].phone`, true);
  }, [values.contacts, setFieldTouched]);

  return (
    <>
      <div className="fw-bold fs-4 mb-4 mt-4">
        {t("secondStep.returnContact")}
      </div>

      <FieldArray name="contacts">
        {({ insert, remove }) => (
          <>
            {values.contacts.map((_, index) => (
              <Row key={index} className="g-3 mb-3">
                <Col lg={6}>
                  <InputText
                    name={`contacts[${index}].email`}
                    placeholder={t("secondStep.email")}
                  />
                </Col>
                <Col lg={6} className="d-flex gap-3">
                  <InputPhone
                    country={values.countryCode}
                    name={`contacts[${index}].phone`}
                    value={values.contacts[index].phone}
                    onChange={(phone) =>
                      setFieldValue(`contacts[${index}].phone`, phone)
                    }
                    onBlur={() =>
                      setFieldTouched(`contacts[${index}].phone`, true)
                    }
                  />
                  {index > 0 && (
                    <Button
                      variant="outline-secondary"
                      className="text-dark"
                      style={{ width: 58, height: 58 }}
                      onClick={() => remove(index)}
                    >
                      <Trash width={18} height={18} />
                    </Button>
                  )}
                </Col>
              </Row>
            ))}

            {values.contacts.length < 5 && (
              <Button
                variant="link"
                className="text-success mt-3"
                onClick={() =>
                  insert(values.contacts.length, { email: "", phone: "" })
                }
              >
                + {t("secondStep.addContact")}
              </Button>
            )}
          </>
        )}
      </FieldArray>
    </>
  );
}
