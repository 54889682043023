import React, { useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import StripeImg from "./img/Stripe";
import { InfoCircle, Check } from "react-bootstrap-icons";
import CountryModal from "./modal/CountryModal";
import { useGetOrganizerPaymentsQuery } from "../../../qraphql/event.hooks";
import StripeModal from "./modal/StripeModal";
import { IEntity, IEventPayment, PaymentTypeEnum } from "../../../types";
import classNames from "classnames";
import { useFormikContext } from "formik";

interface ITicketOnlineProps {
  handleShowBankAccоuntInfo: (isShow: boolean) => void;
  eventPayment: IEventPayment;
  handleUpdateEntity: (values: IEntity, currentStep: number) => Promise<void>;
  eventPaymentsLoading: boolean;
}

export default function TicketOnline({
  handleShowBankAccоuntInfo,
  eventPayment,
  handleUpdateEntity,
  eventPaymentsLoading,
}: ITicketOnlineProps) {
  const { t } = useTranslation();
  const { values } = useFormikContext<IEntity>();
  const { data: organizerPayments, loading: organizerPaymentsLoading } =
    useGetOrganizerPaymentsQuery();
  const isStripe = organizerPayments?.organizer?.payments.some(
    (item) => item.type === PaymentTypeEnum.Stripe
  );
  const isStripeConnectOrDirect =
    (eventPayment?.type === PaymentTypeEnum.Stripe &&
      eventPayment?.stripeConnectBankAccountId) ||
    eventPayment?.type === PaymentTypeEnum.StripeAlternative;
  const [isShowCountryModal, setIsShowCountryModal] = useState(false);

  const handleClose = () => {
    setIsShowCountryModal(false);
  };

  const handleSave = (isStripeConnect: boolean) => {
    handleClose();
    if (!isStripeConnect) {
      handleShowBankAccоuntInfo(true);
    } else {
      handleUpdateEntity(values, 2);
    }
  };

  return (
    <>
      {isShowCountryModal &&
        (isStripe ? (
          <StripeModal
            handleClose={handleClose}
            organizerPayments={organizerPayments}
            handleUpdateEntity={handleUpdateEntity}
          />
        ) : (
          <CountryModal handleClose={handleClose} handleSave={handleSave} />
        ))}
      <div className="fs-5 fw-bold mb-3">{t("thirdStep.paymentOnline")}</div>
      {organizerPaymentsLoading || eventPaymentsLoading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Row className="g-3">
          <Col lg={6}>
            <div
              className={classNames(
                "border rounded-3 py-4 d-flex justify-content-center align-items-center flex-column gap-3 h-100",
                {
                  "border-success bg-success-light": isStripeConnectOrDirect,
                }
              )}
            >
              <StripeImg />
              {isStripeConnectOrDirect ? (
                <div
                  className="border border-stripe rounded-3 text-stripe d-flex justify-content-center align-items-center p-2 fw-bold"
                  style={{ width: 278 }}
                >
                  {t("thirdStep.Stripe Connected")}
                  <Check width={18} height={18} className="ms-2 mb-1 " />
                </div>
              ) : (
                <Button
                  variant={"stripe"}
                  className="fw-bold py-2"
                  style={{ width: 278 }}
                  onClick={() => {
                    setIsShowCountryModal(true);
                  }}
                >
                  {t("thirdStep.connectStripe")}
                </Button>
              )}
            </div>
          </Col>
          <Col lg={6}>
            <StripeInfo />
          </Col>
        </Row>
      )}
    </>
  );
}

const StripeInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-light-gray text-info p-3 rounded d-flex h-100">
      <div>
        <InfoCircle className="pe-2 fs-4" height={24} width={24} />
      </div>
      <div className="fs-7">
        {t("thirdStep.pleaseNote")}{" "}
        <span className="fw-bold">{t("thirdStep.stripeService")}</span>{" "}
        {t("thirdStep.forTransacton")}{" "}
        <span className="fw-bold">{t("thirdStep.cannotChange")}</span>{" "}
        {t("thirdStep.stripeAcc")}
      </div>
    </div>
  );
};
