import { ErrorMessage, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Plus, Pencil, Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { base64ToFile, imageFileToBase64 } from "../../../helpers";
import { useDeleteEventGalleryImageMutation } from "../../../qraphql/event.hooks";
import { IEntity, IEventImage } from "../../../types";

interface IImagesGalery {
  id: string;
  image: string;
}

const IMAGE_SIZE = 75;

export default function Galery() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { setFieldValue, values } = useFormikContext<IEntity>();
  const addGaleryImgRef = useRef<HTMLInputElement>(null);
  const galeryContainerRef = useRef<HTMLDivElement>(null);

  const [imagesGalery, setImagesGalery] = useState<IImagesGalery[]>([]);
  const [currentEditItemId, setCurrentEditItemId] = useState<string>("");
  const [errorSize, setErrorSize] = useState(false);
  const [countPlaceholders, setCountPlaceholders] = useState(6);

  const [deleteEventGalleryImage] = useDeleteEventGalleryImageMutation();

  useEffect(() => {
    if (values.galery && values.galery.length > 0) {
      Promise.all(
        values.galery.map(async (item) => {
          const base64Image = await imageFileToBase64(item.file);
          return { id: item.id, image: base64Image } as IImagesGalery;
        })
      ).then((base64Array) => {
        setImagesGalery(base64Array);
      });
    }
  }, [values.galery, id]);

  useEffect(() => {
    const handleResize = () => {
      if (!galeryContainerRef.current) return;
      setCountPlaceholders(
        Math.floor(
          (galeryContainerRef.current.offsetWidth + 16) / (IMAGE_SIZE + 16)
        )
      );
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files.length) return;

    const readFile = (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };

    const processFiles = async (filesArray: File[]) => {
      if (filesArray.some((file) => file.size > 5 * 1024 * 1024)) {
        setErrorSize(true);
        return;
      } else {
        setErrorSize(false);
      }
      const images = await Promise.all(filesArray.map(readFile));
      const imageIds = images.map((_, index) => `image-${index}-${Date.now()}`);

      if (currentEditItemId) {
        const image = images[0];
        const imageId = imageIds[0];

        setImagesGalery((prev) =>
          prev.map((item) =>
            item.id === currentEditItemId ? { ...item, image } : item
          )
        );
        setFieldValue(
          "galery",
          values.galery.map((item) =>
            item.id === currentEditItemId
              ? {
                  ...item,
                  id: imageId,
                  file: base64ToFile(image, imageId),
                }
              : item
          )
        );
        if (!currentEditItemId.includes("image")) {
          try {
            await deleteEventGalleryImage({
              variables: {
                eventId: values.id,
                imageId: Number(currentEditItemId),
              },
            });
          } catch (error) {}
        }
        setCurrentEditItemId("");
      } else {
        const newImages = images.map((image, index) => ({
          id: imageIds[index],
          image,
        }));
        if (imagesGalery.length >= 12) return;
        setImagesGalery((prev) => [...prev, ...newImages].slice(0, 12));

        const galeryFiles: IEventImage[] = values.galery || [];
        newImages.forEach(({ id, image }) => {
          if (galeryFiles.length >= 12) return;
          galeryFiles.push({
            id,
            file: base64ToFile(image, id),
          });
        });
        setFieldValue("galery", galeryFiles);
      }
    };

    const filesArray = Array.from(files);
    processFiles(filesArray);

    event.target.value = null;
  };

  const handleDeleteGaleryItem = async (id: string) => {
    if (!id.includes("image")) {
      try {
        await deleteEventGalleryImage({
          variables: { eventId: values.id, imageId: Number(id) },
        });
      } catch (error) {}
    }
    setFieldValue(
      "galery",
      values.galery.filter((item) => item.id !== id)
    );
    const updateGalery = imagesGalery.filter((item) => item.id !== id);
    setImagesGalery(updateGalery);
  };

  const handleEditGaleryItem = async (id: string) => {
    setCurrentEditItemId(id);
    if (addGaleryImgRef.current) {
      addGaleryImgRef.current.multiple = false;
      addGaleryImgRef.current.click();
      addGaleryImgRef.current.multiple = true;
    }
  };

  return (
    <>
      <Row className="mt-md-5 mt-4 gy-3">
        <Col xs={12} md={5}>
          <div className="fs-8 mb-2 fw-bold">
            {t("firstStep.galery")}{" "}
            <span className="fw-normal text-info fst-italic">
              - {t("firstStep.optional")}
            </span>
          </div>
          <div className="text-info fs-7">{t("firstStep.galeryInfo")}</div>
        </Col>
        <Col xs={12} md={7}>
          <>
            <div
              className="d-flex justify-content-start gap-3 flex-wrap"
              ref={galeryContainerRef}
              style={{ maxWidth: 6 * IMAGE_SIZE + 16 * 5 }}
            >
              {imagesGalery.map((item) => (
                <div
                  key={item.id}
                  className="position-relative rounded-3 p-1 border"
                  style={{
                    width: IMAGE_SIZE,
                    height: IMAGE_SIZE,
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={item.image}
                    alt="galery item"
                    className="rounded-3"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div className="position-absolute top-50 start-50 translate-middle d-flex gap-1">
                    <div
                      className="rounded-circle bg-white d-flex justify-content-center align-items-center cursor-pointer"
                      style={{ width: 22, height: 22 }}
                      onClick={() => handleEditGaleryItem(item.id)}
                    >
                      <Pencil width={12} height={12} />
                    </div>
                    <div
                      className="rounded-circle bg-white d-flex justify-content-center align-items-center cursor-pointer"
                      style={{ width: 22, height: 22 }}
                      onClick={() => handleDeleteGaleryItem(item.id)}
                    >
                      <Trash width={12} height={12} />
                    </div>
                  </div>
                </div>
              ))}
              {imagesGalery.length < 12 &&
                Array.from({
                  length:
                    imagesGalery.length < countPlaceholders
                      ? countPlaceholders - imagesGalery.length
                      : 1,
                }).map((_, index) => (
                  <div
                    key={index}
                    className="rounded-3 p-1 dashed-button cursor-pointer"
                    style={{ width: IMAGE_SIZE, height: IMAGE_SIZE }}
                    onClick={() => addGaleryImgRef.current?.click()}
                  >
                    <div className="bg-success-light h-100 rounded-3 text-success d-flex justify-content-center align-items-center flex-column">
                      <Plus className="fs-2" />
                    </div>
                  </div>
                ))}
            </div>
            <ErrorMessage
              name="galery"
              component="div"
              className="text-danger error-field"
            />
            {errorSize && (
              <div className="text-danger error-field">
                {t("firstStep.maxSize")} 5 MB
              </div>
            )}
            <input
              ref={addGaleryImgRef}
              type="file"
              style={{ display: "none" }}
              accept=".png, .jpg, .jpeg, .webp"
              onChange={handleImageChange}
              multiple
            />
          </>
        </Col>
      </Row>
    </>
  );
}
