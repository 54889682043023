import { ErrorMessage, useField } from "formik";
import React from "react";
import { FloatingLabel, Form, FormControlProps } from "react-bootstrap";

interface IInputTextAreaProps extends FormControlProps {
  height?: number;
  rows?: number;
}
export default function InputTextArea({
  rows,
  height,
  className,
  ...props
}: IInputTextAreaProps) {
  const [field, meta] = useField(props.name);

  return (
    <FloatingLabel label={props.placeholder} className={className}>
      <Form.Control
        as="textarea"
        {...field}
        {...props}
        isInvalid={meta.touched && !!meta.error}
        isValid={meta.touched && !meta.error}
        rows={rows || 4}
        style={{ height: height || 100 }}
      />
      <ErrorMessage
        name={props.name}
        component="div"
        className="text-danger error-field"
      />
    </FloatingLabel>
  );
}
