import { useFormikContext } from "formik";
import { useEffect } from "react";
import { IEntity } from "../../types";

const ScrollToFieldError: React.FC = () => {
  const { submitCount, isValid, errors } = useFormikContext<IEntity>();

  useEffect(() => {
    if (isValid || submitCount === 0) return;
    if (Object.keys(errors).length > 0) {
      document.querySelectorAll(".error-field")[0]?.scrollIntoView({
        block: "center",
      });
    }
  }, [submitCount, isValid]);

  return null;
};

export default ScrollToFieldError;
