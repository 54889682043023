import React from "react";
import { Col, Row } from "react-bootstrap";
import RadioBoxWithImage from "../../../components/inputs/radioBoxWithImage";
import NonReturnableImg from "./img/NonReturnable";
import ReturnableImg from "./img/Returnable";
import { InfoCircle } from "react-bootstrap-icons";
import { useFormikContext } from "formik";
import { IEntity } from "../../../types";
import { useTranslation } from "react-i18next";
import InputTextArea from "../../../components/inputs/inputTextArea";

export default function TicketReturn() {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<IEntity>();

  return (
    <>
      <div className="fw-bold fs-4 mb-4 mt-5">
        {t("secondStep.ticketReturn")}
      </div>
      <div className="bg-light-gray text-info p-3 rounded d-flex mb-3">
        <div>
          <InfoCircle className="pe-2 fs-4" height={26} width={26} />
        </div>
        {t("secondStep.returnInfo")}
      </div>
      <Row className="gx-3 gy-2 mb-4">
        <Col xs={12} md={6}>
          <RadioBoxWithImage
            label={t("secondStep.nonReturnable")}
            name="eventFormat"
            id="inline-radio-offline"
            img={<NonReturnableImg />}
            checked={!values.isReturnable}
            onChange={() => setFieldValue("isReturnable", false)}
          />
        </Col>
        <Col xs={12} md={6}>
          <RadioBoxWithImage
            label={t("secondStep.returnable")}
            name="eventFormat"
            id="inline-radio-online"
            img={<ReturnableImg />}
            checked={values.isReturnable}
            onChange={() => setFieldValue("isReturnable", true)}
          />
        </Col>
      </Row>
      {values.isReturnable && (
        <>
          <InputTextArea
            placeholder={t("secondStep.returnPolicy")}
            name="returnDescription"
            height={100}
          />
          <div className="d-flex justify-content-between text-info fs-7 mt-1 gap-3">
            <div>{t("secondStep.termsInfo")}</div>
            <div>{values.returnDescription.length}/1000</div>
          </div>
        </>
      )}
    </>
  );
}
