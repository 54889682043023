import React, { useEffect, useRef } from "react";
import { IEntity } from "../../types";
import { Formik, FormikHelpers, Form as FormikForm, FormikProps } from "formik";
import Wrapper from "../../components/wrapper";
import { Col, Row, Button } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import EventType from "./eventType";
import EventFormat from "./eventFormat";
import InputText from "../../components/inputs/inputText";
import InputDateTime from "../../components/inputs/inputDateTime";
import InputTextArea from "../../components/inputs/inputTextArea";
import StepPagination from "../../components/stepPagination";
import EventMedia from "./eventMedia";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { validationSchemaStep1 } from "../../validations";
import Categories from "./Categories";
import Performers from "./Performers";
import EventLoadingWrapper from "../../components/wrapper/EventLoadingWrapper";
import ErrorEventLoadingWrapper from "../../components/wrapper/ErrorEventLoadingWrapper";
import useGetEventData from "../../hooks/useGetEventData";
import { clearLocalStorageItems, isLocalStorageData } from "../../helpers";
import ScrollToFieldError from "../../components/ScrollToFieldError";
import useGetUrlLocale from "../../hooks/useGetUrlLocale";

export interface IFirstProps {
  entity: IEntity;
  handleUpdateEntity: (values: IEntity, currentStep: number) => Promise<void>;
  step: number;
}

export default function First({
  entity,
  handleUpdateEntity,
  step,
}: IFirstProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const urlLocale = useGetUrlLocale();
  const formRef = useRef<FormikProps<IEntity>>(null);
  const { id } = useParams();

  const {
    getEventData,
    handleTryFetchImages,
    isEventLoading,
    eventError,
    errorLoadImageFiles,
  } = useGetEventData({ handleUpdateEntity, step: 0 });
  useEffect(() => {
    const isNoUpdateParam = new URLSearchParams(window.location.search).has(
      "noupdate"
    );
    if (
      id &&
      isLocalStorageData() &&
      Number(id) !== (JSON.parse(localStorage.getItem("entity")) as IEntity).id
    ) {
      // clear local storage if eventId in url is not equal eventId in storage, need get updated event data
      clearLocalStorageItems();
    } else if (!isNoUpdateParam) {
      clearLocalStorageItems();
    }
  }, []);

  useEffect(() => {
    if (id && !isLocalStorageData()) {
      getEventData();
    }
  }, [id]);

  const onSubmit = (
    values: IEntity,
    { setSubmitting }: FormikHelpers<IEntity>
  ) => {
    handleUpdateEntity(values, 1);
    id
      ? navigate(`${urlLocale}/tickets-info/${id}/edit?noupdate=true`)
      : navigate(`${urlLocale}/tickets-info?noupdate=true`);
    setSubmitting(false);
  };

  if (isEventLoading) {
    return (
      <EventLoadingWrapper
        step={step}
        title={t("firstStep.createEvent")}
        stepName={t("firstStep.basicInfo")}
      />
    );
  }

  if (eventError || errorLoadImageFiles) {
    return (
      <ErrorEventLoadingWrapper
        step={step}
        title={t("firstStep.createEvent")}
        errorLoadImageFiles={errorLoadImageFiles}
        eventError={eventError}
        handleTryFetchImages={() => handleTryFetchImages(entity)}
      />
    );
  }

  return (
    <Wrapper>
      <h1 className="fw-bold fs-1 mb-4 mb-md-5">
        {t("firstStep.createEvent")}
      </h1>
      <StepPagination step={step} />
      <div className="fw-bold fs-4 mb-3">{t("firstStep.basicInfo")}</div>
      <Formik
        innerRef={formRef}
        initialValues={entity}
        onSubmit={onSubmit}
        validationSchema={validationSchemaStep1(t)}
        enableReinitialize
      >
        {({ isSubmitting, isValid, submitCount, values }) => {
          return (
            <FormikForm>
              <ScrollToFieldError />
              <InputText
                placeholder={t("firstStep.eventTitle")}
                name="title"
                className="mb-3"
              />
              <Categories />

              <Performers />

              <InputTextArea
                placeholder={t("firstStep.eventDescription")}
                name="description"
                height={100}
              />
              <div className="text-info fs-7 mb-5 mt-1">
                {t("firstStep.descriptionInfo")} {values.description.length}
              </div>

              <div className="fw-bold fs-4 mb-3">{t("firstStep.dateTime")}</div>
              <Row className="gx-3 gy-1 mb-3">
                <Col xs={12} md={6}>
                  <InputDateTime
                    placeholder={t("firstStep.startDate")}
                    name="startDate"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <InputDateTime
                    placeholder={t("firstStep.endDate")}
                    name="endDate"
                  />
                </Col>
              </Row>
              <div className="bg-light-gray text-info p-3 rounded d-flex mb-3">
                <div>
                  <InfoCircle className="pe-2 fs-4" height={26} width={26} />
                </div>
                {t("firstStep.singleEvents")}
              </div>

              <EventType />

              <EventFormat />

              <EventMedia />

              <Row className="justify-content-center my-5">
                <Col lg={4}>
                  <Button
                    variant="success"
                    className="fw-bold w-100 py-3"
                    type="submit"
                    disabled={isSubmitting || (submitCount > 0 && !isValid)}
                  >
                    {t("firstStep.next")}
                  </Button>
                </Col>

                {submitCount > 0 && !isValid && (
                  <div className="bg-danger bg-opacity-10 text-danger p-3 rounded mt-3">
                    {t("firstStep.Fix errors to proceed")}
                  </div>
                )}
              </Row>
            </FormikForm>
          );
        }}
      </Formik>
    </Wrapper>
  );
}
