export interface IEntity {
  id: number;
  title: string;
  description: string;
  categories: string[];
  isRecurring: boolean;
  isOnline: boolean;
  startDate: string | null;
  endDate: string | null;
  repeats: string;
  recurrence: IEventRecurrence;
  country: string;
  countryCode: string;
  city: string;
  address: string;
  place: string;
  placeName: string;
  eventInvitationLink: string;
  guidelinesPlatformUse: string;
  poster: File | null;
  banner: IEventImage | null;
  galery: IEventImage[] | null;
  performers: string[];
  newPerformers: string[];
  isReturnable: boolean;
  returnDescription: string;
  contacts: IContact[];
  tickets: ITicket[];
  isPayOnline: boolean;
  ticketAddresses: string[];
  merchantContacts: IContact[];
  bankConnect: IBankConnect;
  bankDirect: IBankDirect;
  stripe: IStripe;
}

export interface IContact {
  email: string;
  phone: string;
}

export interface ITicket {
  typeId: number;
  active: boolean;
  ticketType: TicketTypeEnum;
  quantity: number;
  limitQuantity: number;
  currency: string;
  description: string;
  price?: number;
  date?: string | null;
  id: number | null;
}
export interface SelectOption {
  label: string;
  value: string;
}

export interface SelectOptionNested {
  label: string;
  options: {
    value: string;
    label: string;
  }[];
}

export interface IBankConnect {
  currency: string;
  country: string;
  holderName: string;
  iban: string;
}

export interface IBankDirect {
  email: string;
  phone: string;
  address: string;
  city: string;
  postalCode: number;
  countryResidence: string;
  countryAccount: string;
  bankName: string;
  holderName: string;
  iban: string;
  isIncludeFee: boolean;
}

interface IStripe {
  country: string;
  email: string;
}

export enum PaymentTypeEnum {
  StripeAlternative = "stripe_alternative",
  Stripe = "stripe",
  Cash = "cash",
}

export interface IEventPayment {
  __typename?: "PaymentsProxyType";
  type?: string;
  stripeConnectBankAccountId?: string;
}

export interface IEventImage {
  id: string;
  file: File;
}

export interface IEventRecurrence {
  repeatsEvery: number;
  repeatsType: string;
  weekDays: boolean[];
  endRecurrence: EndRecurrenceEnum;
  onDate: string | null;
  endAfterOccurences: number;
}

export enum EndRecurrenceEnum {
  NeverStop = "NeverStop",
  OnDate = "OnDate",
  After = "After",
}

export enum RepeatPeriodEnum {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
  EveryWeekday = "EveryWeekday",
  Custom = "Custom",
  Yearly = "Yearly",
}

export enum TicketTypeEnum {
  Regular = "regular",
  Free = "free",
  Vip = "vip",
  FanZone = "fan zone",
  Balcony = "balcony",
  Child = "child",
  Student = "student",
  Senior = "senior",
  MerchBundle = "merch bundle",
  SingleDayPass = "single day pass",
  WeekendPass = "weekend pass",
  EarlyBird = "early bird",
  LastMinute = "last minute",
}

export const NEW_PLACE = "newPlace";
