import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./locales/en/translation.json";
import deTranslation from "./locales/de/translation.json";
import ukTranslation from "./locales/uk/translation.json";
import viTranslation from "./locales/vi/translation.json";
import arTranslation from "./locales/ar/translation.json";
import thTranslation from "./locales/th/translation.json";
import koTranslation from "./locales/ko/translation.json";
import jaTranslation from "./locales/ja/translation.json";
import itTranslation from "./locales/it/translation.json";
import hiTranslation from "./locales/hi/translation.json";
import msTranslation from "./locales/ms/translation.json";
import idTranslation from "./locales/id/translation.json";
import esTranslation from "./locales/es/translation.json";
import frTranslation from "./locales/fr/translation.json";
import ptTranslation from "./locales/pt/translation.json";
import nlTranslation from "./locales/nl/translation.json";
import svTranslation from "./locales/sv/translation.json";
import plTranslation from "./locales/pl/translation.json";

if (i18next.isInitialized) {
  // Добавляем или обновляем переводы для текущего языка
  i18next.addResourceBundle("en", "translation", enTranslation, true, true);
  i18next.addResourceBundle("uk", "translation", ukTranslation, true, true);
  i18next.addResourceBundle("de", "translation", deTranslation, true, true);
  i18next.addResourceBundle("vi", "translation", viTranslation, true, true);
  i18next.addResourceBundle("ar", "translation", arTranslation, true, true);
  i18next.addResourceBundle("th", "translation", thTranslation, true, true);
  i18next.addResourceBundle("ko", "translation", koTranslation, true, true);
  i18next.addResourceBundle("ja", "translation", jaTranslation, true, true);
  i18next.addResourceBundle("it", "translation", itTranslation, true, true);
  i18next.addResourceBundle("hi", "translation", hiTranslation, true, true);
  i18next.addResourceBundle("ms", "translation", msTranslation, true, true);
  i18next.addResourceBundle("id", "translation", idTranslation, true, true);
  i18next.addResourceBundle("es", "translation", esTranslation, true, true);
  i18next.addResourceBundle("fr", "translation", frTranslation, true, true);
  i18next.addResourceBundle("pt", "translation", ptTranslation, true, true);
  i18next.addResourceBundle("nl", "translation", nlTranslation, true, true);
  i18next.addResourceBundle("sv", "translation", svTranslation, true, true);
  i18next.addResourceBundle("pl", "translation", plTranslation, true, true);
} else {
  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
      fallbackLng: "en", // запасной язык, если текущий не доступен
      debug: true, // выводит информацию для отладки в консоль
      detection: {
        order: [
          "queryString",
          "cookie",
          "localStorage",
          "navigator",
          "htmlTag",
        ],
        caches: ["cookie"], // кэширует информацию о языке пользователя
      },
      //   backend: {
      //     // пути для загрузки переводов, `%{lng}` будет заменено на текущий язык
      //     loadPath: "/locales/{{lng}}/translation.json",
      //   },
      resources: {
        en: { translation: enTranslation },
        uk: { translation: ukTranslation },
        de: { translation: deTranslation },
        vi: { translation: viTranslation },
        ar: { translation: arTranslation },
        th: { translation: thTranslation },
        ko: { translation: koTranslation },
        ja: { translation: jaTranslation },
        it: { translation: itTranslation },
        hi: { translation: hiTranslation },
        ms: { translation: msTranslation },
        id: { translation: idTranslation },
        es: { translation: esTranslation },
        fr: { translation: frTranslation },
        pt: { translation: ptTranslation },
        nl: { translation: nlTranslation },
        sv: { translation: svTranslation },
        pl: { translation: plTranslation },
        // ...add other languages
      },
      interpolation: {
        escapeValue: false, // не экранирует значения
      },
    });
}
