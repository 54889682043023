import React from "react";
import Poster from "./Poster";
import Galery from "./Galery";
import Banner from "./Banner";
import { useTranslation } from "react-i18next";

export default function EventMedia() {
  const { t } = useTranslation();

  return (
    <>
      <div className="fw-bold fs-4 mb-4 mt-5">{t("firstStep.eventMedia")}</div>
      <Poster />
      <Galery />
      <Banner />
    </>
  );
}
