import { ErrorMessage, useField } from "formik";
import React from "react";
import { FloatingLabel, Form, FormControlProps } from "react-bootstrap";

export default function InputText({ className, ...props }: FormControlProps) {
  const [field, meta] = useField(props.name);

  return (
    <FloatingLabel label={props.placeholder} className={className}>
      <Form.Control
        type="text"
        {...field}
        {...props}
        isInvalid={meta.touched && !!meta.error}
        isValid={meta.touched && !meta.error}
      />
      <ErrorMessage
        name={props.name}
        component="div"
        className="text-danger error-field"
      />
    </FloatingLabel>
  );
}
