import React from "react";
const Stripe = ({ disabled = false }: { disabled?: boolean }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={62} height={24} fill="none">
    <path
      fill={disabled ? "#DDE0E4" : "#635BFF"}
      d="M4.7 9.405c0-.625.543-.866 1.444-.866 1.292 0 2.924.369 4.216 1.026V5.8c-1.41-.529-2.805-.737-4.216-.737-3.45 0-5.746 1.698-5.746 4.534 0 4.422 6.46 3.717 6.46 5.623 0 .737-.68.978-1.632.978-1.41 0-3.213-.545-4.64-1.282v3.813c1.58.64 3.178.913 4.64.913 3.536 0 5.967-1.65 5.967-4.518C11.176 10.35 4.7 11.2 4.7 9.404ZM16.19 1.858l-4.148.834-.017 12.833c0 2.37 1.887 4.117 4.403 4.117 1.394 0 2.414-.24 2.975-.529v-3.252c-.544.208-3.23.945-3.23-1.426V8.748h3.23V5.335h-3.23l.017-3.477Zm8.5 4.663-.272-1.186h-3.672v14.019h4.25v-9.5C26 8.618 27.7 8.843 28.227 9.02V5.335c-.544-.192-2.533-.545-3.536 1.186Zm4.573-1.186h4.267v14.019h-4.267V5.335Zm0-1.218 4.267-.865V0l-4.267.85v3.267Zm13.141.946c-1.666 0-2.737.737-3.332 1.25l-.22-.994h-3.74V24l4.25-.85.016-4.533c.612.416 1.513 1.01 3.01 1.01 3.042 0 5.813-2.308 5.813-7.387-.017-4.646-2.822-7.177-5.797-7.177Zm-1.02 11.039c-1.003 0-1.598-.337-2.006-.754l-.017-5.943c.442-.465 1.054-.786 2.023-.786 1.547 0 2.618 1.635 2.618 3.733 0 2.147-1.054 3.75-2.618 3.75ZM61.598 12.4c0-4.1-2.108-7.337-6.137-7.337-4.046 0-6.494 3.236-6.494 7.305 0 4.823 2.89 7.258 7.038 7.258 2.023 0 3.553-.432 4.71-1.041V15.38c-1.157.545-2.483.882-4.166.882-1.649 0-3.11-.545-3.298-2.436h8.313c0-.208.034-1.041.034-1.426ZM53.2 10.88c0-1.81 1.173-2.564 2.244-2.564 1.037 0 2.142.753 2.142 2.564H53.2Z"
    />
  </svg>
);
export default Stripe;
