import { ErrorMessage, useField } from "formik";
import React from "react";
import { Form, FormControlProps } from "react-bootstrap";

export default function InputDateSmall({ ...props }: FormControlProps) {
  const [field, meta] = useField(props.name);

  return (
    <>
      <Form.Control
        type="date"
        {...field}
        {...props}
        isInvalid={meta.touched && !!meta.error}
        isValid={meta.touched && !meta.error}
      />
      <ErrorMessage
        name={props.name}
        component="div"
        className="text-danger error-field"
      />
    </>
  );
}
