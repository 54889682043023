import React from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";
import { IEntity, SelectOption } from "../../../types";
import { useTranslation } from "react-i18next";
import SelectInput from "../../../components/inputs/select";
import InputText from "../../../components/inputs/inputText";
import {
  useGetCountriesQuery,
  useGetStripeCurrenciesQuery,
} from "../../../qraphql/event.hooks";

export default function PaymentConnect() {
  const { t } = useTranslation();
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<IEntity>();
  const { data: countriesData } = useGetCountriesQuery();
  const { data: stripeCurrenciesData } = useGetStripeCurrenciesQuery();

  const optionsCountries =
    countriesData?.countries
      .map((item) => ({
        value: item.code,
        label: item.country,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const optionsCurrencies =
    stripeCurrenciesData?.stripeCurrencies?.map((item) => ({
      value: item.name,
      label: item.name,
    })) || [];

  return (
    <>
      <div className="fw-bold fs-4 mb-3">{t("thirdStep.bankAccInfo")}</div>
      <Row className="g-3 gy-2 pb-3">
        <Col lg={6}>
          <SelectInput
            options={optionsCurrencies}
            label={t("secondStep.currency")}
            name={`bankConnect.currency`}
            placeholder={t("thirdStep.selectCurrency")}
            onBlur={() => setFieldTouched("bankConnect.currency", true)}
            onChange={(selectedOption: SelectOption | null) => {
              setFieldValue(
                "bankConnect.currency",
                selectedOption ? selectedOption.value : ""
              );
            }}
            value={optionsCurrencies.find(
              (option) => option.value === values.bankConnect.currency
            )}
          />
        </Col>
        <Col lg={6}>
          <SelectInput
            options={optionsCountries}
            label={t("thirdStep.bankCountry")}
            name={`bankConnect.country`}
            placeholder={t("thirdStep.selectBankCountry")}
            onBlur={() => setFieldTouched("bankConnect.country", true)}
            onChange={(selectedOption: SelectOption | null) => {
              setFieldValue(
                "bankConnect.country",
                selectedOption ? selectedOption.value : ""
              );
            }}
            value={optionsCountries.find(
              (option) => option.value === values.bankConnect.country
            )}
          />
        </Col>
        <Col lg={6}>
          <InputText
            placeholder={t("thirdStep.holderName")}
            name={`bankConnect.holderName`}
          />
        </Col>
        <Col lg={6}>
          <InputText
            placeholder={t("thirdStep.iban")}
            name={`bankConnect.iban`}
          />
        </Col>
      </Row>
    </>
  );
}
