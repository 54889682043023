import React from "react";
import { Col, Row } from "react-bootstrap";
import RadioBoxWithImage from "../../../components/inputs/radioBoxWithImage";
import SingleEventImg from "./img/SingleEvent";
import RecurringEventImg from "./img/RecurringEvent";
import SelectInput from "../../../components/inputs/select";
import CustomRecurrenceSettings from "./CustomRecurrenceSettings";
import { useFormikContext } from "formik";
import { IEntity, RepeatPeriodEnum, SelectOption } from "../../../types";
import { useTranslation } from "react-i18next";
import { getDayNumber } from "../../../helpers";

export default function EventType() {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<IEntity>();

  const date = new Date(values.startDate);
  const dayName = date.toLocaleDateString("en-US", { weekday: "long" });
  const dayOfMonth = date.toLocaleDateString("en-US", { day: "numeric" });

  const repeatsOptions = [
    {
      label: "Daily",
      value: RepeatPeriodEnum.Daily,
    },
    {
      label: `Weekly on ${dayName}`,
      value: RepeatPeriodEnum.Weekly,
    },
    {
      label: `Monthly on ${getDayNumber(date).nameCount} ${dayName}`,
      value: RepeatPeriodEnum.Monthly,
    },
    {
      label: `Annually on the ${dayName} ${dayOfMonth}`,
      value: RepeatPeriodEnum.Yearly,
    },
    {
      label: `Every weekday (Monday to Friday)`,
      value: RepeatPeriodEnum.EveryWeekday,
    },
    {
      label: t("firstStep.customRecurrence"),
      value: RepeatPeriodEnum.Custom,
    },
  ];

  return (
    <>
      <Row className="gx-3 gy-2 mb-3">
        <Col xs={12} md={6}>
          <RadioBoxWithImage
            label={t("firstStep.singleEvent")}
            name="isRecurring"
            id="inline-radio-single"
            img={<SingleEventImg />}
            checked={!values.isRecurring}
            onChange={() => setFieldValue("isRecurring", false)}
          />
        </Col>
        <Col xs={12} md={6}>
          <RadioBoxWithImage
            label={t("firstStep.recurringEvent")}
            name="isRecurring"
            id="inline-radio-recurring"
            img={<RecurringEventImg />}
            checked={values.isRecurring}
            onChange={() => setFieldValue("isRecurring", true)}
            disabled
          />
        </Col>
      </Row>
      {values.isRecurring && (
        <>
          <SelectInput
            options={repeatsOptions}
            placeholder={t("firstStep.selectRepeats")}
            label={t("firstStep.repeats")}
            name="repeats"
            className="mb-3"
            value={repeatsOptions.find(
              (option) => option.value === values.repeats
            )}
            onChange={(selectedOption: SelectOption | null) => {
              setFieldValue(
                "repeats",
                selectedOption ? selectedOption.value : ""
              );
            }}
          />
          {values.repeats === RepeatPeriodEnum.Custom && (
            <CustomRecurrenceSettings />
          )}
        </>
      )}
    </>
  );
}
