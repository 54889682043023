import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DEFAULT_ENTITY, tickets } from "../constants";
import {
  formatDateTime,
  freqRuleToPeriodEnumString,
  getFileFromUrl,
  getReccurrenceFromRule,
} from "../helpers";
import {
  GetEventProxyQuery,
  useGetEventProxyLazyQuery,
} from "../qraphql/event.hooks";
import {
  IBankDirect,
  IContact,
  IEntity,
  PaymentTypeEnum,
  RepeatPeriodEnum,
  TicketTypeEnum,
} from "../types";
import { RRule } from "rrule";

export interface IUseGetEventDataProps {
  handleUpdateEntity: (values: IEntity, currentStep: number) => Promise<void>;
  step: number;
}

const useGetEventData = ({
  handleUpdateEntity,
  step,
}: IUseGetEventDataProps) => {
  const [isDataProcessing, setIsDataProcessing] = useState(false);
  const [errorLoadImageFiles, setErrorLoadImageFiles] = useState(false);

  const { id } = useParams();

  const [
    getEvent,
    { data: eventData, loading: eventLoading, error: eventError },
  ] = useGetEventProxyLazyQuery();

  const getEventData = () => {
    getEvent({
      variables: { eventId: Number(id) },
      fetchPolicy: "network-only",
    });
  };

  const handleTryFetchImages = async (entity: IEntity) => {
    setIsDataProcessing(true);
    const { posterFile, bannerFile, galeryFiles } = await fetchEventFiles(
      eventData,
      () => {}
    );
    if (
      posterFile instanceof File &&
      galeryFiles.every((item) => item.file instanceof File)
    ) {
      setErrorLoadImageFiles(false);
      handleUpdateEntity(
        {
          ...entity,
          poster: posterFile,
          banner: bannerFile,
          galery: galeryFiles,
        },
        0
      );
    } else {
      setErrorLoadImageFiles(true);
    }
    setIsDataProcessing(false);
  };

  useEffect(() => {
    if (!eventData) return;

    const handleErrorLoadImageFiles = (isError: boolean) => {
      setErrorLoadImageFiles(isError);
    };

    setIsDataProcessing(true);
    updateEvent(eventData, handleErrorLoadImageFiles)
      .then((event) => handleUpdateEntity(event, step))
      .catch((err) => console.error(err))
      .finally(() => setIsDataProcessing(false));
    // eslint-disable-next-line
  }, [eventData]);

  const isEventLoading = eventLoading || isDataProcessing;

  return {
    getEventData,
    handleTryFetchImages,
    isEventLoading,
    eventError,
    errorLoadImageFiles,
  };
};

const updateEvent = async (
  eventData: GetEventProxyQuery,
  handleErrorLoadImageFiles: (isError: boolean) => void
) => {
  const { eventProxy } = eventData;

  const { posterFile, bannerFile, galeryFiles } = await fetchEventFiles(
    eventData,
    handleErrorLoadImageFiles
  );

  const returnContacts =
    eventProxy.ticketReturn.contacts.length > 0
      ? eventProxy.ticketReturn.contacts.map((item) => ({
          email: item?.email || "",
          phone: (item?.phone || "").replace("+", ""),
        }))
      : DEFAULT_ENTITY.contacts;

  const eventTickets = [...tickets];
  eventProxy.tickets.forEach((item) => {
    const tiketIndex = eventTickets.findIndex(
      (el) => el.ticketType === item.type.name.toLowerCase()
    );

    if (tiketIndex !== -1) {
      const startTicketSales =
        eventTickets[tiketIndex].ticketType === TicketTypeEnum.LastMinute
          ? {
              date: item.startTicketSales
                ? formatDateTime(item.startTicketSales)
                : "",
            }
          : {};
      const endTicketSales =
        eventTickets[tiketIndex].ticketType === TicketTypeEnum.EarlyBird
          ? {
              date: item.endTicketSales
                ? formatDateTime(item.endTicketSales)
                : "",
            }
          : {};
      eventTickets[tiketIndex] = {
        ...eventTickets[tiketIndex],
        active: true,
        quantity: item?.quantity || 1,
        limitQuantity: item?.limitQuantity || 20,
        description: item?.description || "",
        price: item?.price || null,
        ...startTicketSales,
        ...endTicketSales,
        id: item?.id || null,
      };
      eventTickets.forEach((item) => {
        item.currency = eventProxy.tickets[0]?.currency || "USD";
      });
    }
  });

  const merchantContacts: IContact[] = [];
  const merchantEmails = (eventProxy.eventCashInfos.length > 0 &&
    eventProxy.eventCashInfos[0].emails) || [""];
  const merchantPhones = (eventProxy.eventCashInfos.length > 0 &&
    eventProxy.eventCashInfos[0].phones) || [""];
  merchantEmails.forEach((item, index) => {
    merchantContacts[index] = { ...merchantContacts[index], email: item };
  });

  merchantPhones.forEach((item, index) => {
    merchantContacts[index] = {
      ...merchantContacts[index],
      phone: item.indexOf("+") !== -1 ? item.replace("+", "") : item,
    };
  });

  const payment = eventProxy.payments[0]?.stripeAlternative;
  const bankDirect: IBankDirect = {
    email: payment?.email || "",
    phone: (payment?.phone || "").replace("+", ""),
    address: payment?.address || "",
    city: "",
    postalCode: payment?.postalCode ? Number(payment.postalCode) : null,
    countryResidence: "",
    countryAccount: "",
    bankName: payment?.nameOfTheBank || "",
    holderName: payment?.accountHolderName || "",
    iban: payment?.iban || "",
    isIncludeFee: payment?.feePayerType ? Boolean(payment.feePayerType) : true,
  };

  const repeats = !eventProxy.isRecurring
    ? RepeatPeriodEnum.Daily
    : !eventProxy.recurrence.rrule.includes("INTERVAL")
      ? freqRuleToPeriodEnumString(
          RRule.fromString(eventProxy.recurrence.rrule)
        )
      : RepeatPeriodEnum.Custom;

  const recurrence =
    eventProxy.isRecurring && eventProxy.recurrence.rrule.includes("INTERVAL")
      ? getReccurrenceFromRule(RRule.fromString(eventProxy.recurrence.rrule))
      : DEFAULT_ENTITY.recurrence;

  const event: IEntity = {
    title: eventProxy.title || "",
    id: Number(eventProxy.id),
    categories: eventProxy.categories.map((item) => item.id.toString()) || [],
    description: eventProxy.description || "",
    startDate: eventProxy.isRecurring
      ? formatDateTime(eventProxy.recurrence.startDate)
      : (eventProxy?.startDate && formatDateTime(eventProxy.startDate)) || null,
    endDate: eventProxy.isRecurring
      ? formatDateTime(eventProxy.recurrence.endDate)
      : (eventProxy?.endDate && formatDateTime(eventProxy.endDate)) || null,
    isOnline: eventProxy.isOnline,
    country: eventProxy?.place?.city?.country?.id.toString() || "",
    countryCode:
      eventProxy?.place?.city?.country?.code.toLocaleLowerCase() || "us",
    city: eventProxy?.place?.city?.id.toString() || "",
    address: "",
    place: eventProxy?.place?.id.toString() || "",
    placeName: "",
    eventInvitationLink: eventProxy?.platform?.invitationLink || "",
    guidelinesPlatformUse: eventProxy?.platform?.platformUseGuidelines || "",
    poster: posterFile,
    banner: bannerFile,
    galery: galeryFiles,
    isRecurring: eventProxy.isRecurring,
    repeats: repeats,
    recurrence: recurrence,
    performers: eventProxy.performers.map((item) => item.id.toString()) || [],
    newPerformers: [],
    isReturnable: eventProxy?.ticketReturn.isPossible,
    returnDescription: eventProxy?.ticketReturn?.description || "",
    contacts: returnContacts,
    tickets: eventTickets,
    isPayOnline:
      eventProxy.payments.length > 0 &&
      eventProxy.payments[0].type === PaymentTypeEnum.Cash
        ? false
        : true,
    ticketAddresses: eventProxy.eventCashInfos[0]?.addresses || [""],
    merchantContacts,
    bankConnect: { currency: "", country: "", holderName: "", iban: "" },
    bankDirect,
    stripe: { country: "", email: "" },
  };

  return event;
};

const fetchEventFiles = async (
  eventData: GetEventProxyQuery,
  handleErrorLoadImageFiles: (isError: boolean) => void
) => {
  const { eventProxy } = eventData;
  const posterFile = await fetchFile(
    eventProxy.posterImage.link,
    handleErrorLoadImageFiles
  );

  const bannerFile = eventProxy?.bannerImage
    ? {
        id: String(eventProxy.bannerImage.id),
        file: await fetchFile(
          eventProxy.bannerImage.link,
          handleErrorLoadImageFiles
        ),
      }
    : null;

  const galeryFiles = await Promise.all(
    (eventProxy.galleryImages || []).map(async (item) => ({
      id: String(item.id),
      file: await fetchFile(item.link, handleErrorLoadImageFiles),
    }))
  );

  return {
    posterFile,
    bannerFile,
    galeryFiles,
  };
};

const fetchFile = async (
  image: string,
  handleErrorLoadImageFiles?: (isError: boolean) => void
): Promise<File | null> => {
  if (!image) return null;
  try {
    return await getFileFromUrl(image);
  } catch (error) {
    handleErrorLoadImageFiles && handleErrorLoadImageFiles(true);
    return null;
  }
};

export default useGetEventData;
