import React from "react";
import { useTranslation } from "react-i18next";
import InputText from "../../../components/inputs/inputText";
import InputTextArea from "../../../components/inputs/inputTextArea";

export default function OnlineFields() {
  const { t } = useTranslation();

  return (
    <>
      <div className="fw-bold fs-4 mb-4">{t("firstStep.eventPlatform")}</div>
      <InputText
        placeholder={t("firstStep.eventLink")}
        name="eventInvitationLink"
        className="mb-3"
      />
      <InputTextArea
        placeholder={t("firstStep.guidelines")}
        name="guidelinesPlatformUse"
        height={100}
        maxLength={255}
      />
      <div className="text-info fs-7 mt-1">{t("firstStep.guidelinesInfo")}</div>
    </>
  );
}
