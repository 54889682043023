import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import SelectInput from "../../components/inputs/select";
import { useGetCategoriesQuery } from "../../qraphql/event.hooks";
import { IEntity, SelectOption, SelectOptionNested } from "../../types";

export default function Categories() {
  const { t } = useTranslation();
  const { setFieldValue, values, setFieldTouched } =
    useFormikContext<IEntity>();
  const { data: categoriesData, loading } = useGetCategoriesQuery();

  const filteredCategories: SelectOptionNested[] =
    categoriesData?.categoriesProxy
      .filter((category) => category.children.length > 0)
      .map((item) => ({
        label: item.title,
        options: [
          { value: item.id.toString(), label: item.title },
          ...item.children.map((child) => ({
            value: child.id.toString(),
            label: child.title,
          })),
        ].sort((a, b) => a.label.localeCompare(b.label)),
      })) || [];

  const handleChange = (selectedOption: SelectOption[] | null) => {
    setFieldValue(
      "categories",
      selectedOption ? selectedOption.map((item) => item.value) : []
    );
  };

  const value = values.categories.map((categoryId) => {
    // Find the category object that contains the selected category
    const category = filteredCategories.find((cat) =>
      cat.options.some((opt) => opt.value === String(categoryId))
    );
    // Find the option object within the category
    return category?.options.find((opt) => opt.value === String(categoryId));
  });

  return (
    <>
      <SelectInput
        options={filteredCategories}
        placeholder={t("firstStep.selectCategory")}
        label={t("firstStep.category")}
        name="categories"
        isMulti
        className="mb-3"
        onChange={handleChange}
        value={value}
        onBlur={() => setFieldTouched("categories", true)}
        isLoading={loading}
      />
    </>
  );
}
