import { useTranslation } from "react-i18next";

const useFormatedDate = () => {
  const { t } = useTranslation();
  const formatedDate = (input: string): string => {
    const date = new Date(input);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = t(`fourthStep.${months[date.getUTCMonth()]}`);
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    const paddedDay = day < 10 ? "0" + day : day;
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    return `${month}/${paddedDay}/${year}, ${hours}:${minutes}`;
  };

  return { formatedDate };
};

export default useFormatedDate;
