import classNames from "classnames";
import React from "react";
import { Form, FormCheckProps } from "react-bootstrap";
import { useField } from "formik";

interface IRadioBoxWithImageProps extends FormCheckProps {
  img: React.ReactNode;
}

export default function RadioBoxWithImage({
  img,
  ...props
}: IRadioBoxWithImageProps) {
  const [field] = useField(props.name);
  return (
    <>
      <Form.Label
        className={classNames(
          "px-3 py-4 rounded-3 border border-1 w-100 position-relative",
          {
            "bg-success-light": props.checked,
            "border-success": props.checked,
            "bg-secondary": props.disabled,
          }
        )}
      >
        <Form.Check type="radio" {...field} {...props} />
        <div
          className={classNames("position-absolute top-50 translate-middle", {
            "opacity-50": props.disabled,
          })}
          style={{ right: 0 }}
        >
          {img}
        </div>
      </Form.Label>
    </>
  );
}
